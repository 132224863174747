import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useSubmission } from "../providers/ProblemSubmissionProvider";

interface SubmissionProtectedRouteProps {
    children: React.ReactNode;
}

const SubmissionProtectedRoute: React.FC<SubmissionProtectedRouteProps> = ({ children }) => {
    const { problem_id } = useParams<{ problem_id: string }>();
    const { isSubmitted, isLoading } = useSubmission();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isSubmitted) {
        return <Navigate to={`/problems/${problem_id}`} />;
    }

    return <>{children}</>;
};

export default SubmissionProtectedRoute;
