import { ClassicPreset } from "rete";

export class LogisticRegressionSettings extends ClassicPreset.Control {
    constructor(
        public initialSolver: string,
        public initialPenalty: string,
        public initialC: number,
        public initialMaxIter: number,
        public initialL1Ratio: number,
        public updateL1Ratio: (value: number) => void,
        public updateSolver: (value: string) => void,
        public updatePenalty: (value: string) => void,
        public updateC: (value: number) => void,
        public updateMaxIter: (value: number) => void
    ) {
        super();
    }

    setupControls(controlData: any) {
        this.initialSolver = controlData.initialSolver;
        this.initialPenalty = controlData.initialPenalty;
        this.initialC = controlData.initialC;
        this.initialMaxIter = controlData.initialMaxIter;
        this.initialL1Ratio = controlData.initialL1Ratio;
    }
}
