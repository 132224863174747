import React, { useEffect, useRef, useState } from "react";
import { VisualizerControls } from "../ReteControls/VisualizerControls";
import { Button, Select, Form, InputNumber, Checkbox } from "antd";
import { debouncedAutoSave } from "../editor";
import "../../styles/Visualizer.css";
import ErrorDisplay from "./ErrorDisplay";

const plotTypeOptions = [
    { value: "bar_chart", label: "Bar Chart" },
    { value: "heatmap", label: "Heatmap" },
    { value: "histogram", label: "Histogram" },
    { value: "scatter", label: "Scatter Plot" },
];

const delayFunction = <F extends (...args: any[]) => void>(
    fn: F,
    ms: number
): ((...args: Parameters<F>) => void) => {
    let timer: NodeJS.Timeout | null = null;

    return function (...args: Parameters<F>) {
        clearTimeout(timer as NodeJS.Timeout);
        timer = setTimeout(() => {
            fn(...(args as Parameters<F>));
        }, ms);
    };
};

const delaySetBins = delayFunction(
    (type: string, context: any, logOnly?: boolean) => debouncedAutoSave(type, context, logOnly),
    1000
);

const VisualizerComponent = (props: { data: VisualizerControls }) => {
    const [columns, setColumns] = useState<string[]>([]);
    const [plotType, setPlotType] = useState(props.data.plotType || "");
    const [selectedCategoricalColumn, setSelectedCategoricalColumn] = useState(
        props.data.categoricalColumn || ""
    );
    const [selectedNumericalColumns, setSelectedNumericalColumns] = useState<string[]>(
        props.data.numericalColumns || []
    );
    const [bins, setBins] = useState<number | null>(props.data.bins || null);

    const containerRef = useRef<HTMLDivElement>(null);

    const { df_columns, messages } = props.data;

    const handleClickViewPlots = () => {
        props.data.setIsVisualizerOpen();
        debouncedAutoSave(
            "click_view_plots",
            {
                plotType,
            },
            true
        );
    };

    const handlePlotTypeChange = (value: string) => {
        props.data.setPlotType(value);
        setPlotType(props.data.plotType);
        debouncedAutoSave("set_plot_type", { plotType: value });
    };

    const handleCategoricalColumnChange = (value: string) => {
        setSelectedCategoricalColumn(value);
        props.data.setCategoricalColumn(value);
        debouncedAutoSave("set_categorical_column", { plotType, categorical_column: value });
    };

    const handleNumericalColumnChange = (value: string, index = 0) => {
        if (!selectedNumericalColumns.includes(value) && selectedCategoricalColumn !== value) {
            setSelectedNumericalColumns((prev) => {
                let newNumericalColumns;
                if (plotType === "bar_chart" || plotType === "histogram") {
                    newNumericalColumns = [value];
                } else {
                    newNumericalColumns = [...prev];
                    newNumericalColumns[index] = value;
                }
                props.data.setNumericalColumns(newNumericalColumns);
                debouncedAutoSave("set_numerical_column", {
                    plotType,
                    new_column: value,
                    numerical_columns: newNumericalColumns,
                });
                return newNumericalColumns;
            });
        }
    };

    const handleBinsChange = (value: number | null) => {
        if (value) {
            props.data.setBins(value);
            setBins(value);
            delaySetBins("set_bins", { plotType, bins: value });
        }
    };

    const getElementTotalHeight = (el: HTMLElement) => {
        const styles = window.getComputedStyle(el);
        const margin = parseFloat(styles["marginTop"]) + parseFloat(styles["marginBottom"]);
        return el.offsetHeight + margin;
    };

    const sumImmediateChildrenHeights = (parentDiv: HTMLElement) => {
        let totalHeight = 0;
        for (let child of parentDiv.children) {
            totalHeight += getElementTotalHeight(child as HTMLElement);
        }
        return totalHeight;
    };

    const getCurrentHeight = () => {
        if (!containerRef.current) return 250;

        const controlContainer: HTMLElement = containerRef.current;
        const overallNodeContainer: HTMLElement =
            controlContainer.closest("[data-testid='node']") || document.createElement("div")!;
        const totalRequiredHeight = sumImmediateChildrenHeights(overallNodeContainer);
        // Clear any hardcoded dimensions from the auto arrange plugin
        overallNodeContainer.style.height = "";
        overallNodeContainer.style.width = "";
        return totalRequiredHeight + 5;
    };

    const updateNodeHeightByContent = () => {
        const newHeight = getCurrentHeight();
        props.data.adjustHeight(newHeight);
    };

    const checkIfColumnSelected = (column: string) => {
        return selectedCategoricalColumn === column || selectedNumericalColumns.includes(column);
    };

    useEffect(() => {
        setSelectedCategoricalColumn("");
        setSelectedNumericalColumns([]);
        setTimeout(() => {
            updateNodeHeightByContent();
        }, 50);
    }, [plotType]);

    useEffect(() => {
        setColumns(props.data.df_columns);
        setSelectedCategoricalColumn("");
        setSelectedNumericalColumns([]);
        updateNodeHeightByContent();
    }, [props.data.df_columns]);

    useEffect(() => {
        updateNodeHeightByContent();
    }, [selectedCategoricalColumn, selectedNumericalColumns]);

    return (
        <div
            className="generatePlotControlContainer"
            ref={containerRef}
            onPointerDown={(e) => e.stopPropagation()}
        >
            <Select
                defaultValue={props.data.plotType || "Select Plot Type"}
                dropdownStyle={{ width: "fit-content", minWidth: "175px" }}
                onChange={handlePlotTypeChange}
                options={plotTypeOptions.map((option, index) => {
                    return {
                        value: option.value,
                        title: `Create ${option.label}`,
                        label: option.label,
                    };
                })}
            />
            <ErrorDisplay messages={messages} plotType={plotType} />
            <div className="requiredInputChoices" key={props.data.df_columns.join(",")}>
                {plotType === "bar_chart" && (
                    <>
                        <Form.Item label="Select Categorical Column">
                            <Select
                                defaultValue={selectedCategoricalColumn || "Select Column"}
                                onChange={handleCategoricalColumnChange}
                                dropdownStyle={{ width: "fit-content", minWidth: "175px" }}
                                options={columns.map((column) => {
                                    const disabled = checkIfColumnSelected(column);
                                    return {
                                        value: column,
                                        title: disabled
                                            ? "Column already selected elsewhere"
                                            : `Select ${column}`,
                                        label: column,
                                        disabled: checkIfColumnSelected(column),
                                    };
                                })}
                            />
                        </Form.Item>
                        <Form.Item label="Select Numerical Column (Optional)">
                            <Select
                                defaultValue={selectedNumericalColumns[0] || "Select Column"}
                                onChange={(val) => handleNumericalColumnChange(val)}
                                dropdownStyle={{ width: "fit-content", minWidth: "175px" }}
                                options={columns.map((column) => {
                                    const disabled = checkIfColumnSelected(column);
                                    return {
                                        value: column,
                                        title: disabled
                                            ? "Column already selected elsewhere"
                                            : `Select ${column}`,
                                        label: column,
                                        disabled: checkIfColumnSelected(column),
                                    };
                                })}
                            />
                        </Form.Item>
                    </>
                )}
                {plotType === "histogram" && (
                    <>
                        <Form.Item label="Select Numerical Column">
                            <Select
                                defaultValue={selectedNumericalColumns[0] || "Select Column"}
                                onChange={(val) => handleNumericalColumnChange(val)}
                                dropdownStyle={{ width: "fit-content", minWidth: "175px" }}
                                options={columns.map((column) => ({
                                    value: column,
                                    title: `Select ${column}`,
                                    label: column,
                                }))}
                            />
                        </Form.Item>
                        <Form.Item label="Number of Bins">
                            <InputNumber
                                min={1}
                                max={100}
                                defaultValue={bins || 10}
                                step={1}
                                onChange={handleBinsChange}
                            />
                        </Form.Item>
                    </>
                )}

                {plotType === "scatter" && (
                    <>
                        <Form.Item label="Select X-Axis Column">
                            <Select
                                defaultValue={selectedNumericalColumns[0] || "Select Column"}
                                onChange={(val) => handleNumericalColumnChange(val, 0)}
                                dropdownStyle={{ width: "fit-content", minWidth: "175px" }}
                                options={columns.map((column) => {
                                    const disabled = checkIfColumnSelected(column);
                                    return {
                                        value: column,
                                        title: disabled
                                            ? "Column already selected elsewhere"
                                            : `Select ${column}`,
                                        label: column,
                                        disabled,
                                    };
                                })}
                            />
                        </Form.Item>
                        <Form.Item label="Select Y-Axis Column">
                            <Select
                                defaultValue={selectedNumericalColumns[1] || "Select Column"}
                                onChange={(val) => handleNumericalColumnChange(val, 1)}
                                dropdownStyle={{ width: "fit-content", minWidth: "175px" }}
                                options={columns.map((column) => {
                                    const disabled = checkIfColumnSelected(column);
                                    return {
                                        value: column,
                                        title: disabled
                                            ? "Column already selected elsewhere"
                                            : `Select ${column}`,
                                        label: column,
                                        disabled,
                                    };
                                })}
                            />
                        </Form.Item>
                        <Form.Item label="Select Categorical Column">
                            <Select
                                defaultValue={selectedCategoricalColumn || "Select Column"}
                                onChange={handleCategoricalColumnChange}
                                dropdownStyle={{ width: "fit-content", minWidth: "175px" }}
                                options={columns.map((column) => {
                                    const disabled = checkIfColumnSelected(column);
                                    return {
                                        value: column,
                                        title: disabled
                                            ? "Column already selected elsewhere"
                                            : `Select ${column}`,
                                        label: column,
                                        disabled,
                                    };
                                })}
                            />
                        </Form.Item>
                    </>
                )}
            </div>
            <Button onClick={handleClickViewPlots}>View Plots</Button>
        </div>
    );
};

export default VisualizerComponent;
