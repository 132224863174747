import React, { RefObject, useCallback, useContext, useEffect, useRef, useState } from "react";
import {
    createEditor,
    globalArea,
    globalEditor,
    globalProcess,
    globalSocket,
    globalUpdateAsset,
} from "./editor";
import { useRete } from "rete-react-plugin";
import ProblemContext from "../contexts/ProblemContext";
import SaveIndicator from "./TextComponents/SaveIndicator";
import VisualizerModal from "./VisualizerModal";
import ImageGalleryModal from "./ImageGalleryModal";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import EditorOverlayContainer from "./EditorOverlayContainer";
import AIChatbot from "./AIChatbot";
import { createNode } from "../helpers/importExportFunctions";
import LeftSideBar from "./LeftSideBar";
import RightSideBar from "./RightSideBar";
import { userSettingsType } from "./App";
import ModuleViewer from "./ModuleViewer";

export const AUTO_SAVE_PENDING_SECONDS = 3;

interface MainEditorProps {
    setIsAuthenticated: (isAuthenticated: boolean) => void;
    isLoading: boolean;
    isAdmin: boolean;
    userSettings: userSettingsType;
    username: string;
}

interface ImageData {
    id: number;
    image_path: string;
    image_title: string;
    is_temporary?: boolean;
}

export interface EditorNodeData {
    blockType: string;
}

export interface CodePath {
    code: string;
    pathNum: number;
}

const MainEditor = ({
    setIsAuthenticated,
    isLoading,
    isAdmin,
    userSettings,
    username,
}: MainEditorProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [autoSaveStatus, setAutoSaveStatus] = useState("");
    const [codePreview, setCodePreview] = useState("");
    const [codePaths, setCodePaths] = useState<CodePath[]>([]);
    const [runProgram, setRunProgram] = useState(false);
    const [generatedPlots, setGeneratedPlots] = useState<string[]>([]);
    const [plotType, setPlotType] = useState("");
    const [storedImages, setStoredImages] = useState<ImageData[] | null>(null);
    const [isVisualizerOpen, setIsVisualizerOpen] = useState(false);
    const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);
    const [editorNodes, setEditorNodes] = useState<EditorNodeData[]>([]);
    const [consoleText, setConsoleText] = useState("Click 'Run Program' to view console output");
    const isContextMenuSource = useRef(false);
    const leftColumnRef = useRef<HTMLDivElement>(null);
    const rightColumnRef = useRef<HTMLDivElement>(null);
    const openChatbotButtonRef = useRef<HTMLButtonElement>(null);
    const openModuleViewerButtonRef = useRef<HTMLButtonElement>(null);

    const [resetTrigger, setResetTrigger] = useState(false);
    const context = useContext(ProblemContext);
    if (!context) {
        throw new Error("ProblemContext must be used within a ProblemProvider");
    }
    const { problem, setProblem } = context;

    let { problem_id: problem_id_string } = useParams();
    const problem_id = Number(problem_id_string);

    const initEditor = useCallback((el: HTMLElement) => {
        return createEditor(
            el,
            context,
            setCodePreview,
            setAutoSaveStatus,
            setResetTrigger,
            setIsVisualizerOpen,
            setPlotType,
            problem_id,
            setEditorNodes,
            isContextMenuSource,
            setCodePaths
        );
    }, []);
    const [ref] = useRete(initEditor);

    useEffect(() => {
        if (!isLoading && isNaN(problem_id)) {
            navigate("/problems");
        }
        if (problem?.problem_status === "not started") {
            setProblem((prev) => {
                if (prev) {
                    return { ...prev, problem_status: "in-progress" };
                }
                return prev;
            });
        }
    }, [problem_id, problem]);

    useEffect(() => {
        if (problem?.adminOnly && !isAdmin) {
            navigate("/problems");
        }
    }, [problem]);

    useEffect(() => {
        if (problem?.problem_status === "completed") {
            navigate(`/problems/${problem_id}/submission`);
        }
    }, [location]);

    const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (!ref.current) {
            return;
        }
        const blockType = e.dataTransfer.getData("blockType");
        const rect = (ref as RefObject<HTMLDivElement>).current?.getBoundingClientRect();
        const x = e.clientX - (rect?.left || 0);
        const y = e.clientY - (rect?.top || 0);
        addElementToEditor(blockType, { x, y });
    };

    const addElementToEditor = async (
        blockType: string,
        globalPosition: { x: number; y: number }
    ) => {
        if (!blockType) {
            return;
        }
        isContextMenuSource.current = false;
        const node = createNode(blockType, {
            socket: globalSocket,
            updateAsset: globalUpdateAsset,
            process: globalProcess,
            context,
            setIsVisualizerOpen,
        });
        // Get the pan and zoom of editor window for relative positioning in editor coordinate frame
        const transform = globalArea.area.transform;
        const dropPositionCoordinates = {
            x: (globalPosition.x - transform.x) / transform.k,
            y: (globalPosition.y - transform.y) / transform.k,
        };

        await globalEditor.addNode(node);
        await globalArea.translate(node.id, dropPositionCoordinates);
    };

    return (
        <div className="BlockEditorContainer">
            <LeftSideBar
                leftRef={leftColumnRef}
                setIsAuthenticated={setIsAuthenticated}
                userSettings={userSettings}
                username={username}
            />
            <div className="mainCenterColumn">
                <div className="editorTopInterface">
                    <EditorOverlayContainer
                        setRunProgram={setRunProgram}
                        runProgram={runProgram}
                        setIsImageGalleryOpen={setIsImageGalleryOpen}
                        leftColumnRef={leftColumnRef}
                        rightColumnRef={rightColumnRef}
                        isAdmin={isAdmin}
                        codePreview={codePreview}
                        consoleText={consoleText}
                        editorRef={ref}
                        openChatbotButtonRef={openChatbotButtonRef}
                        openModuleViewerButtonRef={openModuleViewerButtonRef}
                        generatedPlots={generatedPlots}
                    />
                </div>
                <div
                    className="editorContainer"
                    ref={ref}
                    onDragOver={onDragOver}
                    onDrop={handleDrop}
                >
                    <SaveIndicator
                        status={autoSaveStatus}
                        setStatus={setAutoSaveStatus}
                        max_interval_seconds={AUTO_SAVE_PENDING_SECONDS}
                        resetTrigger={resetTrigger}
                    />

                    <div className="bottomEditorButtons">
                        {(!userSettings || userSettings?.chatgpt_enabled) && (
                            <AIChatbot
                                leftColumnRef={leftColumnRef}
                                rightColumnRef={rightColumnRef}
                                openChatbotButtonRef={openChatbotButtonRef}
                            />
                        )}

                        <ModuleViewer
                            leftColumnRef={leftColumnRef}
                            rightColumnRef={rightColumnRef}
                            openModuleViewerButtonRef={openModuleViewerButtonRef}
                        />
                    </div>
                </div>
            </div>
            <RightSideBar
                codePreview={codePreview}
                runProgram={runProgram}
                setRunProgram={setRunProgram}
                setGeneratedPlots={setGeneratedPlots}
                rightRef={rightColumnRef}
                codePaths={codePaths}
                consoleText={consoleText}
                setConsoleText={setConsoleText}
            />
            <VisualizerModal
                isVisualizerOpen={isVisualizerOpen}
                generatedPlots={generatedPlots}
                setIsVisualizerOpen={setIsVisualizerOpen}
                setIsImageGalleryOpen={setIsImageGalleryOpen}
                plotType={plotType}
                setStoredImages={setStoredImages}
            />
            <ImageGalleryModal
                isImageGalleryOpen={isImageGalleryOpen}
                setIsImageGalleryOpen={setIsImageGalleryOpen}
                setStoredImages={setStoredImages}
                storedImages={storedImages}
            />
        </div>
    );
};

export default MainEditor;
