import { Dispatch, SetStateAction } from "react";
import { ClassicPreset } from "rete";

export class VisualizerControls extends ClassicPreset.Control {
    plotType: string = "";
    df_columns: string[] = [];
    generatedPlots: string[] = [];
    categoricalColumn: string = "";
    numericalColumns: string[] = [];
    bins: number | null = null;
    constructor(
        public setIsVisualizerOpen: () => void,
        public setPlotType: (type: string) => void,
        public setCategoricalColumn: (column: string) => void,
        public setNumericalColumns: (columns: string[]) => void,
        public setBins: (bins: number) => void,
        public adjustHeight: (value: number) => void,
        public messages: string[]
    ) {
        super();
    }

    _setPlotType(type: string) {
        this.plotType = type;
    }

    setDfColumns(columns: string[]) {
        this.df_columns = columns;
    }

    setGeneratedPlots(plots: string[]) {
        this.generatedPlots = plots;
    }

    _setCategoricalColumn(column: string) {
        this.categoricalColumn = column;
    }

    _setNumericalColumns(columns: string[]) {
        this.numericalColumns = columns;
    }

    _setBins(bins: number) {
        this.bins = bins;
    }

    clearMessages() {
        this.messages = [];
    }

    addMessage(message: string) {
        this.messages.push(message);
    }

    setupControls(controlData: any) {
        this.plotType = controlData.plotType;
        this.df_columns = controlData.df_columns;
        this.generatedPlots = controlData.generatedPlots;
        this.categoricalColumn = controlData.categoricalColumn || "";
        this.numericalColumns = controlData.numericalColumns || [];
        this.bins = controlData.bins;
    }
}
