// ProblemProvider.tsx
import React, { useState, ReactNode, useCallback, useMemo, useEffect, useRef } from "react";
import { StepProps } from "antd";
import ProblemContext from "../contexts/ProblemContext";
import { ProblemInterface } from "../components/EditorOverlayContainer";
import { axiosInstance, getDetailsFromAction, saveUserLog, UserLog } from "../helpers/nodeHelpers";
import DOMPurify from "dompurify";
import { useParams } from "react-router-dom";
import { debouncedAutoSave } from "../components/editor";

export interface ProblemProps extends StepProps {
    action: string;
    description: string;
    task_status: string;
    title: string;
    id: number;
    task_number: number;
}

interface ProblemProviderProps {
    children: ReactNode;
}

const ProblemProvider: React.FC<ProblemProviderProps> = ({ children }) => {
    // const [completedTasks, setCompletedTasks] = useState<string[]>([]);
    const [tasks, setTasks] = useState<ProblemProps[]>([]);
    const [problem, setProblem] = useState<ProblemInterface | undefined>();
    const [problem_id, setProblem_id] = useState<number | undefined>();
    const tasksRef = useRef(tasks);

    const markTaskAsCompleted = useCallback(
        async (action: string) => {
            const task = tasksRef.current.find((task) => task.action === action);
            if (!task) {
                return;
            }

            try {
                const res = await axiosInstance.post(
                    "/api/tasks/complete-task",
                    { task_id: task.id },
                    { withCredentials: true }
                );
                if (res.status !== 200) {
                    throw new Error(`Failed to mark task ${action} as completed`);
                }
                setTasks((prevTasks) =>
                    prevTasks.map((task) =>
                        task.action === action ? { ...task, task_status: "completed" } : task
                    )
                );
            } catch (error) {
                console.log(error);
            }
        },
        [tasks]
    );

    const removeTaskFromCompleted = useCallback(
        async (action: string) => {
            const task = tasksRef.current.find((task) => task.action === action);
            if (!task) {
                return;
            }

            try {
                const res = await axiosInstance.post(
                    "/api/tasks/revoke-task",
                    { task_id: task.id },
                    { withCredentials: true }
                );
                if (res.status !== 200) {
                    throw new Error(`Failed to revoke task completion for ${action}`);
                }
                setTasks((prevTasks) =>
                    prevTasks.map((task) =>
                        task.action === action ? { ...task, task_status: "not completed" } : task
                    )
                );
            } catch (error) {
                console.log(error);
            }
        },
        [tasks]
    );

    const currentTask = useMemo(() => {
        // Find the index of the first step that is not completed
        const firstIncompleteTaskIndex = tasks.findIndex(
            (task) => task.task_status === "not completed"
        );
        return firstIncompleteTaskIndex !== -1 ? firstIncompleteTaskIndex : tasks.length;
    }, [tasks]);

    useEffect(() => {
        const getProblemData = async () => {
            try {
                const res = await axiosInstance.get(`/api/problems/get-problem/${problem_id}`, {
                    withCredentials: true,
                });
                const { tasks, ...problem } = res.data;
                const cleanProblemDescription = DOMPurify.sanitize(problem.description, {
                    ALLOWED_ATTR: ["target", "href", "src", "alt", "title", "rel"],
                });
                if (problem.problem_status === "not started") {
                    const eventData = {
                        problem_number: problem.problem_number,
                        date_started: new Date().toLocaleString(),
                    };

                    const logData: UserLog = {
                        action: "start_problem",
                        problem_id: problem_id,
                        eventData,
                        details: getDetailsFromAction("start_problem", eventData),
                    };
                    saveUserLog(logData);
                }
                setProblem({ ...problem, description: cleanProblemDescription });
                setTasks(tasks);
            } catch (error) {
                console.log(error);
            }
        };
        if (problem_id) {
            getProblemData();
        } else {
            setProblem(undefined);
            setTasks([]);
        }
    }, [problem_id]);

    useEffect(() => {
        tasksRef.current = tasks;
    }, [tasks]);

    // The value object that will be passed to the context
    const contextValue = {
        setProblem_id,
        tasks,
        setTasks,
        problem,
        setProblem,
        currentTask,
        markTaskAsCompleted,
        removeTaskFromCompleted,
    };

    return <ProblemContext.Provider value={contextValue}>{children}</ProblemContext.Provider>;
};

export default ProblemProvider;
