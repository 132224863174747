import { ClassicPreset } from "rete";

export class DataframeInspectorControl extends ClassicPreset.Control {
    constructor(
        public option: string,
        public options: string[],
        public onOptionSelect: (option: string) => void
    ) {
        super();
    }

    setOption(option: string) {
        this.option = option;
    }

    setupControls(controlData: any) {
        this.option = controlData.option;
    }
}
