import React, { useEffect, useState } from "react";

interface SaveIndicatorProps {
    status: string;
    setStatus: React.Dispatch<React.SetStateAction<string>>;
    max_interval_seconds: number;
    resetTrigger: boolean;
}

const SaveIndicator = ({
    status,
    setStatus,
    max_interval_seconds,
    resetTrigger,
}: SaveIndicatorProps) => {
    const [countDown, setCountDown] = useState(0);

    useEffect(() => {
        if (status) {
            setCountDown(max_interval_seconds);
        }
    }, [resetTrigger]);

    useEffect(() => {
        if (status === "pending") {
            const timer = setInterval(() => {
                setCountDown((prev) => prev - 0.25);
            }, 250);

            if (countDown <= 0) {
                clearInterval(timer);
            }

            return () => clearInterval(timer);
        }
    }, [countDown]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;
        if (status === "saved") {
            timeoutId = setTimeout(() => {
                setStatus("");
            }, 2000);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [status]);

    return (
        <div className="saveIndicatorContainer">
            <span className="saveIndicatorText">
                {status === "pending" && `Pending auto-save in ${countDown.toFixed(0)}...`}
                {status === "saved" && "Successfully saved!"}
            </span>
        </div>
    );
};

export default SaveIndicator;
