import React, { useCallback, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";

import MainEditor from "./MainEditor";
import Register from "./Register";
import Login from "./Login";
import ProtectedRoute from "./ProtectedRoute";
import { axiosInstance } from "../helpers/nodeHelpers";
import ProblemSelection from "./ProblemSelection";
import ProblemDescription from "./ProblemDescription";
import ProblemProvider from "../providers/ProblemProvider";
import ProblemSubmissionSummary from "./ProblemSubmissionSummary";
import ProblemSubmissionProvider from "../providers/ProblemSubmissionProvider";
import SubmissionProtectedRoute from "./SubmissionProtectedRoute";
import LogoutComponent from "./LogoutComponent";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { RECAPTCHA_SITE_KEY } from "../config";

export const checkIfAuthenticated = async () => {
    try {
        const response = await axiosInstance.get("/api/users/verify-authentication", {
            withCredentials: true,
        });
        const data = response.data;
        return {
            is_authenticated: data.is_authenticated,
            is_admin: data.is_admin,
            userSettings: data.userSettings,
            username: data.username,
        };
    } catch (error) {
        return { is_authenticated: false, is_admin: false };
    }
};

export type userSettingsType = {
    taskbar_enabled: boolean;
    chatgpt_enabled: boolean;
} | null;

export default function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [username, setUsername] = useState("");
    const [userSettings, setUserSettings] = useState<userSettingsType>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const verifyAuthentication = async () => {
            const authenticationData = await checkIfAuthenticated();
            setIsAuthenticated(authenticationData.is_authenticated);
            setIsAdmin(authenticationData.is_admin);
            setUsername(authenticationData.username);
            setUserSettings(authenticationData.userSettings);
            setIsLoading(false);
        };
        verifyAuthentication();
    }, []);

    return (
        <div className="App">
            <Router>
                <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                    <ProblemProvider>
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <ProtectedRoute
                                        isAuthenticated={isAuthenticated}
                                        setIsAuthenticated={setIsAuthenticated}
                                        isLoading={isLoading}
                                        setIsAdmin={setIsAdmin}
                                        setUsername={setUsername}
                                        setUserSettings={setUserSettings}
                                        setIsLoading={setIsLoading}
                                    >
                                        <ProblemSelection
                                            isAdmin={isAdmin}
                                            setIsAuthenticated={setIsAuthenticated}
                                            userSettings={userSettings}
                                            username={username}
                                        />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/problems/:problem_id"
                                element={
                                    <ProtectedRoute
                                        isAuthenticated={isAuthenticated}
                                        setIsAuthenticated={setIsAuthenticated}
                                        isLoading={isLoading}
                                        setIsAdmin={setIsAdmin}
                                        setUsername={setUsername}
                                        setUserSettings={setUserSettings}
                                        setIsLoading={setIsLoading}
                                    >
                                        <MainEditor
                                            isLoading={isLoading}
                                            setIsAuthenticated={setIsAuthenticated}
                                            isAdmin={isAdmin}
                                            userSettings={userSettings}
                                            username={username}
                                        />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/problems/:problem_id/description"
                                element={
                                    <ProtectedRoute
                                        isAuthenticated={isAuthenticated}
                                        setIsAuthenticated={setIsAuthenticated}
                                        isLoading={isLoading}
                                        setIsAdmin={setIsAdmin}
                                        setUsername={setUsername}
                                        setUserSettings={setUserSettings}
                                        setIsLoading={setIsLoading}
                                    >
                                        <ProblemDescription inDrawer={false} isAdmin={isAdmin} />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/problems/:problem_id/submission"
                                element={
                                    <ProtectedRoute
                                        isAuthenticated={isAuthenticated}
                                        setIsAuthenticated={setIsAuthenticated}
                                        isLoading={isLoading}
                                        setIsAdmin={setIsAdmin}
                                        setUsername={setUsername}
                                        setUserSettings={setUserSettings}
                                        setIsLoading={setIsLoading}
                                    >
                                        <ProblemSubmissionProvider>
                                            <SubmissionProtectedRoute>
                                                <ProblemSubmissionSummary
                                                    userSettings={userSettings}
                                                    username={username}
                                                />
                                            </SubmissionProtectedRoute>
                                        </ProblemSubmissionProvider>
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/problems"
                                element={
                                    <ProtectedRoute
                                        isAuthenticated={isAuthenticated}
                                        setIsAuthenticated={setIsAuthenticated}
                                        isLoading={isLoading}
                                        setIsAdmin={setIsAdmin}
                                        setUsername={setUsername}
                                        setUserSettings={setUserSettings}
                                        setIsLoading={setIsLoading}
                                    >
                                        <ProblemSelection
                                            isAdmin={isAdmin}
                                            setIsAuthenticated={setIsAuthenticated}
                                            userSettings={userSettings}
                                            username={username}
                                        />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path="/login"
                                element={
                                    <Login
                                        isAuthenticated={isAuthenticated}
                                        setIsAuthenticated={setIsAuthenticated}
                                        isLoading={isLoading}
                                    />
                                }
                            />
                            <Route
                                path="/register"
                                element={
                                    <Register
                                        isAuthenticated={isAuthenticated}
                                        setIsAuthenticated={setIsAuthenticated}
                                        isLoading={isLoading}
                                    />
                                }
                            />
                            <Route
                                path="/logout"
                                element={
                                    <LogoutComponent
                                        setIsAuthenticated={setIsAuthenticated}
                                    ></LogoutComponent>
                                }
                            />
                        </Routes>
                    </ProblemProvider>
                </GoogleReCaptchaProvider>
            </Router>
        </div>
    );
}
