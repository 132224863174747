import { Button, Checkbox, Form, Input } from "antd";
import { RuleObject } from "antd/es/form";
import { axiosInstance } from "../helpers/nodeHelpers";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import PageLoadingMessage from "./TextComponents/PageLoadingMessage";

type FieldType = {
    email?: string;
    username?: string;
    password?: string;
    confirmPassword?: string;
};

const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
};

const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).*$/;

// Custom validator function
const validatePassword = (_: RuleObject, value: string) => {
    if (!value) {
        return Promise.reject(new Error("Password is required"));
    }
    if (!passwordRegex.test(value)) {
        return Promise.reject(
            new Error(
                "Password must include at least one uppercase letter, one number, and one symbol"
            )
        );
    }
    return Promise.resolve();
};

interface RegisterProps {
    isAuthenticated: boolean;
    setIsAuthenticated: (isAuthenticated: boolean) => void;
    isLoading: boolean;
}

const Register = ({ isAuthenticated, setIsAuthenticated, isLoading }: RegisterProps) => {
    const [statusMessage, setStatusMessage] = useState<string | null>(null);
    const [status, setStatus] = useState<"error" | "success" | undefined>(undefined);
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const registerNewUser = async (formData: FieldType) => {
        if (!executeRecaptcha) return;
        try {
            const token = await executeRecaptcha("register");
            const response = await axiosInstance.post(
                "/api/users/register",
                { ...formData, token },
                {
                    withCredentials: true,
                }
            );
            const data = response.data;
            setStatusMessage(data.messages.join("\n"));
            setStatus("success");
            return true;
        } catch (error: any) {
            console.log("Catch error:", error);
            const errorMessages = error.response.data.messages;
            setStatus("error");
            setStatusMessage(errorMessages.join("\n"));
            return false;
        }
    };

    const onFinish = async (values: any) => {
        const successfulRegistration = await registerNewUser(values);
        if (successfulRegistration) {
            setIsAuthenticated(true);
            navigate("/");
        }
    };

    useEffect(() => {
        if (!isLoading) {
            if (isAuthenticated) {
                navigate("/");
            }
        }
    });

    if (isLoading) {
        return <PageLoadingMessage />;
    }

    return (
        <div className="loginContainer">
            <h1>Register</h1>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 14 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item<FieldType>
                    label="Email"
                    name="email"
                    rules={[
                        { required: true, message: "Please enter your email" },
                        { type: "email", message: "Please enter a valid email" },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label="Username"
                    name="username"
                    rules={[
                        { required: true, message: "Please enter a unique username" },
                        { min: 4, message: "Username must be at least 4 characters long" },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label="Password"
                    name="password"
                    rules={[
                        { required: true, message: "Please enter a password" },
                        // { min: 8, message: "Password must be at least 8 characters long" },
                        // { validator: validatePassword },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item<FieldType>
                    label="Confirm Password"
                    name="confirmPassword"
                    rules={[
                        { required: true, message: "Please confirm your password" },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error("The passwords you entered do not match")
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    wrapperCol={{ offset: 8, span: 16 }}
                    help={
                        status === "error" || !status ? (
                            statusMessage
                        ) : (
                            <p style={{ color: "green" }}>{statusMessage}</p>
                        )
                    }
                    validateStatus={status}
                >
                    <Button type="primary" htmlType="submit">
                        Register
                    </Button>
                    <span>
                        Already have an account?<Link to="/login"> Login here</Link>
                    </span>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Register;
