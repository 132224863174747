import { ConfigProvider, Select, theme } from "antd";
import { DataframeInspectorControl } from "../ReteControls/DataframeInspectorControls";
import React, { useState } from "react";
import { Drag } from "rete-react-plugin";
import { debouncedAutoSave } from "../editor";

const DROPDOWN_TEXT_DEFAULT = "Select inspection type";

const DataframeInspectorComponent = (props: { data: DataframeInspectorControl }) => {
    const handleSelect = (value: string) => {
        props.data.onOptionSelect(value);
        debouncedAutoSave("set_dataframe_inspection_option", { inspection_option: value });
    };

    return (
        <div className="dfInspectSelectorContainer">
            <ConfigProvider
                theme={{
                    algorithm: theme.darkAlgorithm,
                }}
            >
                <Drag.NoDrag>
                    <Select
                        className="selectDropDown"
                        onChange={handleSelect}
                        value={props.data.option}
                        defaultValue={DROPDOWN_TEXT_DEFAULT}
                        dropdownStyle={{ width: "fit-content" }}
                        options={props.data.options.map((option, index) => {
                            return {
                                value: option,
                                title: `Inspect ${option}`,
                            };
                        })}
                    />
                </Drag.NoDrag>
            </ConfigProvider>
        </div>
    );
};

export default DataframeInspectorComponent;
