import { Spin } from "antd";
import { DataframeLoaderControl } from "components/ReteControls/DataframeLoaderControls";
import "../../styles/DataframeLoader.css";
import React, { useEffect } from "react";

const DataframeLoaderComponent = (props: { data: DataframeLoaderControl }) => {
    useEffect(() => {
        if (props.data.loadingStatus === "success") {
            props.data.context.markTaskAsCompleted("loadDataframeSuccess");
        }
    }, [props.data.loadingStatus]);

    return (
        <>
            <Spin
                wrapperClassName="dataframeLoadingOverlay"
                size="default"
                spinning={props.data.loadingStatus === "loading"}
            >
                <div className="dataframeLoadingContainer">
                    {(props.data.loadingStatus === "error" ||
                        props.data.loadingStatus === "initial") && (
                        <ul>
                            {props.data.messages.map((message, index) => (
                                <li key={index}>
                                    {"> "}
                                    {message}
                                </li>
                            ))}
                        </ul>
                    )}
                    {props.data.loadingStatus === "success" && (
                        <h3 className="successDfMessage">{">"} Dataframe created ✓</h3>
                    )}
                </div>
            </Spin>
        </>
    );
};

export default DataframeLoaderComponent;
