import { Button, Checkbox, Form, Input } from "antd";
import { axiosInstance } from "../helpers/nodeHelpers";
import React, { useCallback, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import PageLoadingMessage from "./TextComponents/PageLoadingMessage";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
};

const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
};

interface LoginProps {
    isAuthenticated: boolean;
    isLoading: boolean;
    setIsAuthenticated: (isAuthenticated: boolean) => void;
}

const Login = ({ isAuthenticated, setIsAuthenticated, isLoading }: LoginProps) => {
    const [statusMessage, setStatusMessage] = useState<string | null>(null);
    const [status, setStatus] = useState<"error" | "success" | undefined>(undefined);
    const navigate = useNavigate();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const loginUser = useCallback(
        async (formData: FieldType) => {
            if (!executeRecaptcha) {
                console.log("Recaptcha not loaded");
                return;
            }
            try {
                const token = await executeRecaptcha("login");
                const response = await axiosInstance.post(
                    "/api/users/login",
                    { ...formData, token },
                    {
                        withCredentials: true,
                    }
                );
                const data = response.data;
                setStatusMessage(data.messages.join("\n"));
                setStatus("success");
                return true;
            } catch (error: any) {
                console.log("Catch error:", error);
                const errorMessages = error.response.data.messages;
                setStatus("error");
                setStatusMessage(errorMessages.join("\n"));
                return false;
            }
        },
        [executeRecaptcha]
    );

    const onFinish = async (values: any) => {
        const successfulLogin = await loginUser(values);
        if (successfulLogin) {
            setIsAuthenticated(true);
            navigate("/");
        }
    };

    useEffect(() => {
        if (!isLoading) {
            if (isAuthenticated) {
                navigate("/");
            }
        }
    }, [isLoading]);

    if (isLoading) {
        return <PageLoadingMessage />;
    }

    return (
        <div className="loginContainer">
            <h1>Login</h1>
            <Form
                name="basic"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                onSubmitCapture={(e) => e.preventDefault()}
            >
                <Form.Item<FieldType>
                    label="Username"
                    name="username"
                    rules={[{ required: true, message: "Please input your username!" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: "Please input your password!" }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item<FieldType>
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{ offset: 6, span: 16 }}
                >
                    <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Form.Item
                    wrapperCol={{ offset: 6, span: 16 }}
                    help={
                        status === "error" || !status ? (
                            statusMessage
                        ) : (
                            <p style={{ color: "green" }}>{statusMessage}</p>
                        )
                    }
                    validateStatus={status}
                >
                    <Button type="primary" htmlType="submit">
                        Log in
                    </Button>
                    <span>
                        Don't have an account?<Link to="/register"> Register here</Link>
                    </span>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Login;
