import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import "../styles/Notes.css";
import { Button, Input, message, Modal, Typography } from "antd";
import EditableNote from "./EditableNote";
import { axiosInstance } from "../helpers/nodeHelpers";
import ProblemContext from "../contexts/ProblemContext";
import { debouncedAutoSave } from "./editor";

const { Text, Paragraph } = Typography;

const { confirm } = Modal;

type Props = {
    collapsed: boolean;
    setIsOverflowing: (isOverflowing: boolean) => void;
};

export type Note = {
    id: number;
    title?: string;
    content: string;
    date_created: string;
    date_modified: string;
};

const Notes = ({ collapsed, setIsOverflowing }: Props) => {
    const [delayRender, setDelayRender] = useState<boolean>(collapsed);
    const [notes, setNotes] = useState<Note[]>([]);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [errorStatus, setErrorStatus] = useState<"error" | null>(null);
    const [inputValue, setInputValue] = useState<string>("");
    const [titleValue, setTitleValue] = useState<string>("");
    const [hasScrollBar, setHasScrollBar] = useState<boolean>(false);
    const [messageApi, contextHolder] = message.useMessage();

    const noteInputRef = useRef<HTMLTextAreaElement>(null);
    const notesListRef = useRef<HTMLDivElement>(null);

    const problemContext = useContext(ProblemContext);
    if (!problemContext) {
        return <div>Loading...</div>;
    }
    const { problem } = problemContext;

    const generateTempKey = () => {
        let key = Math.floor(Math.random() * 10000);
        while (notes.some((note) => note.id === key)) {
            key = Math.floor(Math.random() * 10000);
        }
        return key;
    };

    const checkScrollbar = useCallback(() => {
        if (notesListRef.current) {
            setHasScrollBar(notesListRef.current.scrollHeight > notesListRef.current.clientHeight);
        }
    }, [notesListRef]);

    const displayInputErrorStatus = (message: string) => {
        setErrorMsg(message);
        setErrorStatus("error");
    };

    const clearInputErrorStatus = () => {
        setErrorMsg("");
        setErrorStatus(null);
    };

    const handleNoteUpdate = async (id: number, content: string, title?: string) => {
        if (content.trim() === "") {
            handleDeleteNote(id);
            return;
        }
        const trimmedInput = content.trim();
        const trimmedTitle = title?.trim();
        try {
            const res = await axiosInstance.post("/api/notes/update-note", {
                note_id: id,
                content: trimmedInput,
                title: trimmedTitle,
            });
            const noteData: Note = res.data.note;
            const date_modified: Date = new Date(noteData.date_modified);
            setNotes((prevNotes) =>
                prevNotes.map((note) =>
                    note.id === id
                        ? {
                              ...note,
                              content: trimmedInput,
                              title: trimmedTitle,
                              date_modified: date_modified.toLocaleString(),
                          }
                        : note
                )
            );
            debouncedAutoSave(
                "update_note",
                { note_id: id, content: trimmedInput, title: trimmedTitle },
                true
            );
            messageApi.success("Note updated successfully");
        } catch (e) {
            console.log(e);
            messageApi.error("Failed to update note");
        }
    };

    const saveNote = async () => {
        const trimmedInput = inputValue.trim();
        if (trimmedInput === "") {
            displayInputErrorStatus("Note cannot be empty");
            if (noteInputRef.current) {
                noteInputRef.current.focus();
            }
            return;
        }
        if (!problem) return;

        const trimmedTitle = titleValue.trim();
        try {
            const res = await axiosInstance.post("/api/notes/save-note", {
                problem_id: problem.id,
                title: trimmedTitle,
                content: trimmedInput,
            });
            const note_id = res.data.note.id;
            const date_created: Date = new Date(res.data.note.date_created);
            const date_modified: Date = new Date(res.data.note.date_modified);
            setNotes((prevNotes) => [
                {
                    id: note_id,
                    content: trimmedInput,
                    title: trimmedTitle,
                    date_created: date_created.toLocaleString(),
                    date_modified: date_modified.toLocaleString(),
                },
                ...prevNotes,
            ]);
            setInputValue("");
            setTitleValue("");
            debouncedAutoSave(
                "save_note",
                { note_id, content: trimmedInput, title: trimmedTitle },
                true
            );
            messageApi.success("New note saved successfully");
        } catch (e) {
            console.log(e);
            displayInputErrorStatus("Failed to save note");
            messageApi.error("Failed to save note");
        }
    };

    const handleDeleteNote = async (id: number) => {
        if (!id) return;
        const note = notes.find((note) => note.id === id);
        try {
            await axiosInstance.post("/api/notes/delete-note", { note_id: id });
            setNotes((prevNotes) => prevNotes.filter((note) => note.id !== id));
            debouncedAutoSave(
                "delete_note",
                { note_id: id, content: note?.content, title: note?.title },
                true
            );
            messageApi.success("Note deleted successfully");
        } catch (e) {
            console.log(e);
            messageApi.error("Failed to delete note");
        }
    };

    const handleConfirmDelete = (e: React.MouseEvent, id: number) => {
        const xPosition = e.clientX > 250 ? e.clientX - 200 : 50;
        const yPosition = e.clientY > 250 ? e.clientY - 200 : 50;

        confirm({
            title: "Are you sure you want to delete this note?",
            content: "This action cannot be undone.",
            okText: "Delete",
            okButtonProps: { danger: true },
            async onOk() {
                await handleDeleteNote(id);
            },
            onCancel() {
                messageApi.info("Delete canceled");
            },
            style: {
                position: "fixed",
                top: yPosition,
                left: xPosition,
            },
        });
    };

    const getNotes = async () => {
        if (!problem) return;
        try {
            const response = await axiosInstance.get("/api/notes/get-notes", {
                params: { problem_id: problem.id },
            });
            const noteData: Note[] = response.data.noteData;
            const formattedNotes = noteData
                .sort(
                    (a, b) =>
                        new Date(b.date_modified).getTime() - new Date(a.date_modified).getTime()
                )
                .map((note) => {
                    return {
                        ...note,
                        date_created: new Date(note.date_created).toLocaleString(),
                        date_modified: new Date(
                            note.date_modified ? note.date_modified : ""
                        ).toLocaleString(),
                    };
                });
            setNotes(formattedNotes);
        } catch (error) {
            console.error("Error fetching notes: ", error);
        }
    };

    useEffect(() => {
        getNotes();
    }, [problem]);

    useEffect(() => {
        if (!collapsed) {
            const timer = setTimeout(() => {
                setDelayRender(collapsed);
            }, 300);
            return () => {
                clearTimeout(timer);
            };
        } else {
            setDelayRender(collapsed);
        }
    }, [collapsed]);

    useEffect(() => {
        checkScrollbar();
    }, [notes]);

    useEffect(() => {
        window.addEventListener("resize", checkScrollbar);
        return () => {
            window.removeEventListener("resize", checkScrollbar);
        };
    }, [checkScrollbar]);

    useEffect(() => {
        setIsOverflowing(hasScrollBar);
    }, [hasScrollBar]);

    return (
        <div className="notesContainerOverall">
            {contextHolder}
            {!delayRender && (
                <>
                    <h1>Notes</h1>
                    <div className="notesInputContainer">
                        <Input
                            name="titleInput"
                            className="titleInput"
                            placeholder="Add note title (optional)"
                            autoComplete="off"
                            value={titleValue}
                            onChange={(e) => setTitleValue(e.target.value)}
                        />
                        <Input.TextArea
                            ref={noteInputRef}
                            name="noteContentInput"
                            className="noteInput"
                            placeholder="Add a new note"
                            autoComplete="off"
                            autoSize={{ minRows: 3, maxRows: 10 }}
                            status={errorStatus || ""}
                            value={inputValue}
                            onChange={(e) => {
                                if (errorMsg) {
                                    clearInputErrorStatus();
                                }
                                setInputValue(e.target.value);
                            }}
                            onSubmit={saveNote}
                        />
                        {errorMsg && <p className="noteErrorMessage">{errorMsg}</p>}
                        <Button className="saveNoteBtn" type="primary" onClick={saveNote}>
                            Save Note
                        </Button>
                    </div>
                    <div
                        className={`notesListContainer ${hasScrollBar ? "adjustForScrollBar" : ""}`}
                        ref={notesListRef}
                    >
                        <ul className="notesList">
                            {notes.length === 0 ? (
                                <li>No notes yet</li>
                            ) : (
                                notes.map((note, index) => (
                                    <li key={note.id}>
                                        <p className="noteDateModified">{note.date_modified}</p>
                                        <EditableNote
                                            note={note}
                                            handleNoteUpdate={(value, title?) =>
                                                handleNoteUpdate(note.id, value, title)
                                            }
                                            handleDeleteNote={handleConfirmDelete}
                                        />
                                    </li>
                                ))
                            )}
                        </ul>
                    </div>
                </>
            )}
        </div>
    );
};

export default Notes;
