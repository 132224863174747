import { LinearRegressionSettings } from "../../ReteControls/Models/LinearRegressionSettings";
import { DataOutput, PreviousNodeData } from "../../ReteInterfaces/SharedInterfaces";
import { ClassicPreset } from "rete";

export class LinearRegressionModel extends ClassicPreset.Node<
    {},
    { output: ClassicPreset.Socket },
    { settings: LinearRegressionSettings }
> {
    width = 300;
    height = 200;
    model = "linear_regression";
    fitIntercept = true;
    copyX = true;
    positive = false;
    requiredImports = {
        libraryName: "Scikit-Learn",
        importStatement: "from sklearn.linear_model import LinearRegression",
    };
    update: (type: "node", asset: LinearRegressionModel) => void;
    change: () => void;
    constructor(
        socket: ClassicPreset.Socket,
        update: (type: "node", asset: LinearRegressionModel) => void,
        change: () => void
    ) {
        super("Linear Regression Model");
        this.addControl(
            "settings",
            new LinearRegressionSettings(
                this.fitIntercept,
                this.copyX,
                this.positive,
                this.updateFitIntercept.bind(this),
                this.updateCopyX.bind(this),
                this.updatePositive.bind(this)
            )
        );
        this.addOutput("output", new ClassicPreset.Output(socket, "Untrained Model"));
        this.update = update;
        this.change = change;
    }
    getType() {
        return "LinearRegressionModel";
    }
    getDisplayName() {
        return "Linear Regression Model";
    }
    updateFitIntercept = (value: boolean) => {
        this.fitIntercept = value;
        this.controls.settings.initialFitIntercept = value;
        this.change();
    };

    updateCopyX = (value: boolean) => {
        this.copyX = value;
        this.controls.settings.initialCopyX = value;
        this.change();
    };

    updatePositive = (value: boolean) => {
        this.positive = value;
        this.controls.settings.initialPositive = value;
        this.change();
    };

    getCurrentCode(): string {
        const currentCode = `model = LinearRegression(fit_intercept=${
            this.fitIntercept ? "True" : "False"
        }, copy_X=${this.copyX ? "True" : "False"}, positive=${
            this.positive ? "True" : "False"
        })\n`;
        return currentCode;
    }

    data(inputs: {}): { output: DataOutput } {
        const currentCode = this.getCurrentCode();
        const previousNodes: PreviousNodeData[] = [
            { ...this, connectedPort: this.outputs.output! },
        ];
        const output: DataOutput = {
            code: [currentCode],
            previousNodes,
        };

        return {
            output,
        };
    }
}
