import { Button, Modal, Table } from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import Papa, { ParseResult, ParseConfig } from "papaparse";

import { ParseWorkerConfig } from "papaparse";
import { debouncedAutoSave } from "../../editor";

interface DatasetViewerComponentProps {
    file: File | null;
    useUrl: boolean;
    url: string;
    setDatasetColumns: (columns: string[]) => void;
    fileUploading: boolean;
    setFileUploading: Dispatch<SetStateAction<boolean>>;
    isValidCsvUrl: (url: string) => boolean;
    // signalParseComplete: () => void;
    // completeParsePromise: () => void;
}

interface dataColumns {
    title: string;
    dataIndex: string;
    key: string;
}

interface CustomPapaConfig {
    header: boolean;
    dynamicTyping: boolean;
    complete: (results: ParseResult<any>) => void;
    error: (error: Error) => void;
    download?: boolean;
}

const NO_DATASET_MESSAGE = "--- No dataset loaded ---";
const INVALID_URL_MESSAGE = "--- Invalid URL entered ---";
const LOADING_DATASET_MESSAGE = "--- Loading dataset ---";

const DatasetViewerComponent = ({
    file,
    useUrl,
    url,
    setDatasetColumns,
    fileUploading,
    setFileUploading,
    isValidCsvUrl,
}: // signalParseComplete,
// completeParsePromise,
DatasetViewerComponentProps) => {
    const [moduleMessage, setModuleMessage] = useState<string | null>(NO_DATASET_MESSAGE);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalHeight, setModalHeight] = useState<number>(0);
    const [tableHeight, setTableHeight] = useState<number>(900);
    const [parsedData, setParsedData] = useState<ParseResult<any>["data"]>([]);
    const [fields, setFields] = useState<dataColumns[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFileChanged, setIsFileChanged] = useState(false);
    const [isUrlChanged, setIsUrlChanged] = useState(false);
    const [isValidUrl, setIsValidUrl] = useState(isValidCsvUrl(url));
    const [sourceDataTypeChanged, setSourceDataTypeChanged] = useState(false);
    // const [completePromise, setCompletePromise] = useState(completeParsePromise);

    const parseCsv = (csvSource: any, useUrl: boolean) => {
        return new Promise<void>((resolve, reject) => {
            if (!csvSource) {
                setParsedData([]);
                setFields([]);
                setDatasetColumns([]);
                setModuleMessage(NO_DATASET_MESSAGE);
                reject("No dataset provided - safe to ignore");
                return;
            }
            const config: CustomPapaConfig = {
                header: true,
                dynamicTyping: true,
                download: true,
                complete: (results: ParseResult<any>) => {
                    const hasIndex = results.data[0].hasOwnProperty("id");
                    setParsedData(
                        results.data.map((row, index) => ({
                            ...row,
                            key: index,
                            id: index + 1,
                        }))
                    );

                    if (results.meta.fields) {
                        let tempFields: string[] | undefined = [];
                        if (!hasIndex) {
                            tempFields = ["id"];
                        }
                        const colFields = [...tempFields, ...results.meta.fields].map((field) => {
                            return {
                                title: field,
                                dataIndex: field,
                                key: field,
                                width: "max-content",
                            };
                        });

                        setFields(colFields);
                        setDatasetColumns(colFields.map((field) => field.title));
                    }
                    resolve();
                },
                error: (error: Error) => {
                    setParsedData([]);
                    setFields([]);
                    reject(error);
                },
            };
            if (useUrl) {
                config.download = true;
            }

            Papa.parse(csvSource, config);
        });
    };

    const determineParseRequirement = () => {
        if (!useUrl) {
            if (isFileChanged || sourceDataTypeChanged) {
                setIsFileChanged(false);
                setSourceDataTypeChanged(false);
                return true;
            }
        } else {
            if (isUrlChanged || sourceDataTypeChanged) {
                setIsUrlChanged(false);
                setSourceDataTypeChanged(false);
                return true;
            }
        }
        return false;
    };

    const showModal = async () => {
        setIsModalOpen(true);
        setIsLoading(true);
        setModuleMessage(LOADING_DATASET_MESSAGE);

        let sourceData = useUrl ? url : file;
        let isParseRequired = determineParseRequirement();

        if (isParseRequired) {
            try {
                await parseCsv(sourceData, useUrl);
                setModuleMessage(null);
            } catch (error: any) {
                console.log(error);
                if (useUrl) {
                    console.error("Invalid URL detected");
                    setModuleMessage(INVALID_URL_MESSAGE);
                }
            }
        } else {
            setModuleMessage(null);
        }
        setIsLoading(false);
        const eventData = {
            datasetSource: useUrl ? "url" : "file",
            dataset: useUrl ? url : file?.name,
        };
        debouncedAutoSave("preview_dataset", eventData, true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getAvailableTableHeight = (): number => {
        const modalHeaderHeight = (document.querySelector(".ant-modal-header") as HTMLElement)
            ?.offsetHeight;
        const modalFooterHeight = (document.querySelector(".ant-modal-footer") as HTMLElement)
            ?.offsetHeight;
        const modalHeight = (document.querySelector(".ant-modal") as HTMLElement)?.offsetHeight;
        const modalBodyPadding = 40;
        const availableHeight =
            modalHeight - modalHeaderHeight - modalFooterHeight - modalBodyPadding;
        return availableHeight;
    };

    const updateTableHeight = () => {
        const availableTableHeight = getAvailableTableHeight();
        setTableHeight(availableTableHeight);
    };

    useEffect(() => {
        setIsFileChanged(true);
        if (!useUrl && file) {
            const fetchData = async () => {
                try {
                    await parseCsv(file, useUrl);
                } catch (error) {
                    console.log(error);
                }
                setFileUploading(false);
            };
            fetchData();
        }
    }, [file]);

    useEffect(() => {
        setSourceDataTypeChanged(true);
    }, [useUrl]);

    useEffect(() => {
        const isValid = isValidCsvUrl(url);
        setIsValidUrl(isValid);
        setIsUrlChanged(true);
        if (useUrl && url && isValid) {
            const fetchData = async () => {
                try {
                    await parseCsv(url, useUrl);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        }
    }, [url]);

    useEffect(() => {
        const parseRequired = determineParseRequirement();
        if (parseRequired) {
            let sourceData = useUrl ? url : file;
            const fetchData = async () => {
                try {
                    await parseCsv(sourceData, useUrl);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        }
    }, [sourceDataTypeChanged]);

    useEffect(() => {
        window.addEventListener("resize", updateTableHeight);
        return () => {
            window.removeEventListener("resize", updateTableHeight);
        };
    }, []);

    useEffect(() => {
        if (isModalOpen) {
            setTimeout(() => {
                updateTableHeight();
            }, 100);
        }
    }, [isModalOpen]);

    return (
        <>
            <Button
                type="primary"
                onClick={showModal}
                aria-label="Open Dataset Previewer"
                disabled={(useUrl && (!url || !isValidUrl)) || (!useUrl && !file)}
                loading={fileUploading}
            >
                Preview Dataset
            </Button>
            <Modal
                title="Dataset Previewer"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={"70%"}
                style={{ height: "80vh" }}
                centered={true}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Close
                    </Button>,
                ]}
            >
                <Table
                    columns={fields || []}
                    dataSource={parsedData}
                    loading={{
                        size: "large",
                        spinning: isLoading,
                        tip: "Loading dataset...",
                        wrapperClassName: "datasetLoadingOverlay",
                    }}
                    scroll={{ y: tableHeight - 120, scrollToFirstRowOnChange: true }}
                    style={{ height: tableHeight, maxHeight: "100%" }}
                    pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "50", "100"],
                        defaultPageSize: 50,
                        defaultCurrent: 1,
                    }}
                />
                {moduleMessage && <p className="noFileSelectedMessage">{moduleMessage}</p>}
            </Modal>
        </>
    );
};

export default DatasetViewerComponent;
