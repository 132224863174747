import React, { useEffect, useState } from "react";
import { EditorState, EditorStateNode } from "../helpers/importExportFunctions";
import { Card, ConfigProvider, theme } from "antd";
import { ThemeConsumer } from "styled-components";
import { translateKeys } from "../helpers/nodeHelpers";

type Props = {
    editorState: EditorState;
    node: EditorStateNode;
};

const HideLargeControlDataNodes = ["Code Viewer", "Console Viewer"];

const EditorStateNodeCard = ({ editorState, node }: Props) => {
    const [inputs, setInputs] = useState<string[]>([]);
    const [outputs, setOutputs] = useState<string[]>([]);
    const [activeTab, setActiveTab] = useState<string>(
        node.controlData ? "nodeData" : "connections"
    );
    const tabList = [
        node.controlData && {
            key: "nodeData",
            tab: "Options",
        },
        {
            key: "connections",
            tab: "Connections",
        },
    ];

    const contentList: Record<string, React.ReactNode> = {
        nodeData: (
            <div key={"controlData"}>
                {Object.entries(translateKeys(node.controlData))
                    .filter((item) => item[1]) // Filter out empty values
                    .map((item, index) => {
                        let [key, value] = item;
                        if (Array.isArray(value)) {
                            value = value.join(", ");
                        }
                        if (HideLargeControlDataNodes.includes(node.name)) {
                            value = "Too large to display, see full output below.";
                        }
                        return (
                            <p key={key}>
                                <span className="boldText">{key}</span>
                                {`: ${value}`}
                            </p>
                        );
                    })}
            </div>
        ),
        connections: (
            <div key={"connectionData"} className="usedNodeConnections">
                <div className="usedNodeInputOutputContainer">
                    <h3 className="boldText">Inputs</h3>
                    {inputs.length > 0 ? (
                        inputs.map((input, index) => <p key={index}>{input}</p>)
                    ) : (
                        <p className="noConnectionMessage">No inputs</p>
                    )}
                </div>
                <div className="usedNodeInputOutputContainer">
                    <h3 className="boldText">Outputs</h3>
                    {outputs.length > 0 ? (
                        outputs.map((output, index) => <p key={index}>{output}</p>)
                    ) : (
                        <p className="noConnectionMessage">No outputs</p>
                    )}
                </div>
            </div>
        ),
    };

    const handleTabChange = (key: string) => {
        setActiveTab(key);
    };

    useEffect(() => {
        if (node) {
            setInputs(
                editorState.connections
                    .filter((conn) => conn.target.id === node.id)
                    .map((conn) => conn.source.blockType)
            );
            setOutputs(
                editorState.connections
                    .filter((conn) => conn.source.id === node.id)
                    .map((conn) => conn.target.blockType)
            );
        }
    }, [node]);

    return (
        <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
            <Card
                key={node.id}
                className="editorStateUsedNode"
                title={node.name}
                tabList={tabList}
                activeTabKey={activeTab}
                onTabChange={handleTabChange}
            >
                {contentList[activeTab]}
            </Card>
        </ConfigProvider>
    );
};

export default EditorStateNodeCard;
