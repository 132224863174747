import { LoginOutlined } from "@ant-design/icons";
import { ProblemContextType } from "../../contexts/ProblemContext";
import { ClassicPreset } from "rete";

export class DropdownListControl extends ClassicPreset.Control {
    constructor(
        public options: string[],
        public onOptionSelect: (option: string, updateState: (items: string[]) => void) => void,
        public selectedItems: string[],
        public alreadySelected: (option: string) => boolean,
        public removeImport: (option: string, updateState: (items: string[]) => void) => void,
        public task_id: string,
        public context?: ProblemContextType
    ) {
        super();
    }

    setSelectedItems(items: string[]) {
        this.selectedItems = items;
    }

    setupControls(controlData: any) {
        this.selectedItems = controlData.selectedItems;
    }
}
