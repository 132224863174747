declare global {
    interface Window {
        loadPyodide: any;
    }
}

const parseURLs = (code: string) => {
    const updateCode = code.replace(
        /read_csv\(([^,)]+)(\,\s?)?(.*)?\)/,
        "read_csv(open_url($1)$2$3)"
    );
    return updateCode;
};

const injectUploadedDataset = (code: string, dataset: string) => {
    const safeDataset = dataset.replace(/\n/g, "\\n").replace(/"/g, '\\"');
    const regex = /pd\.read_csv\((.*?)\)/;
    const updatedCode = code.replace(regex, `pd.read_csv(StringIO(\"\"\"${safeDataset}\"\"\"))`);
    return updatedCode;
};

const getCodeGeneratedPlots = (code: string) => {
    let plotCount = 0;
    const plotPaths: string[] = [];
    const parsedCode = code.replace(/(\w+)\.show\(\)/g, function (match, prefix) {
        plotCount += 1;
        const imagePath = `/tmp/figure${plotCount}.png`;
        plotPaths.push(imagePath);
        return `${prefix}.savefig("${imagePath}")`;
    });
    return { parsedCode, plotPaths };
};

// const displayCodeGeneratedPlots = (pyodide, plotPaths) => {
//     const imagesContainer = $("#code-plots-modal").find(".modal-content");
//     imagesContainer.children(":not(.close)").remove();
//     const totalNumPlots = plotPaths.length;
//     let currentIndex = 1;
//     for (const path of plotPaths) {
//         const imageData = pyodide.FS.readFile(path, { encoding: "binary" });
//         const blob = new Blob([imageData.buffer], { type: "image/png" });
//         const singleImageContainer = $(
//             `<div class="image-plot-container">
//                 <div class="image-header">
//                     <h2>Plot ${currentIndex}/${totalNumPlots}</h2>
//                     <button class="btn btn-primary">Save to notes</button>
//                 </div>
//                 <img src="${URL.createObjectURL(blob)}" />
//             </div>`
//         );
//         const saveImageBtn = singleImageContainer.find("button");
//         // addSaveImageEventListener(saveImageBtn, blob);
//         imagesContainer.append(singleImageContainer);
//         currentIndex++;
//     }
// };

const displayCodeGeneratedPlots = (pyodide: any, plotPaths: string[]) => {
    const plotsContainer = document.querySelector(".visualizerModal .plotsContainer")!;

    // Remove all children
    plotsContainer.innerHTML = "";

    const totalNumPlots = plotPaths.length;
    let currentIndex = 1;

    for (const path of plotPaths) {
        const imageData = pyodide.FS.readFile(path, { encoding: "binary" });
        const blob = new Blob([imageData.buffer], { type: "image/png" });

        // Create container for the image
        const singlePlotContainer = document.createElement("div");
        singlePlotContainer.className = "single-plot-container";
        singlePlotContainer.innerHTML = `
            <div class="plot-header">
                <h2>Plot ${currentIndex}/${totalNumPlots}</h2>
            </div>
            <img src="${URL.createObjectURL(blob)}" />
        `;

        // Add event listener to the button
        // const saveImageBtn = singlePlotContainer.querySelector("button");
        // addSaveImageEventListener(saveImageBtn, blob); // Make sure this function is defined

        // Append the new container to the images container
        plotsContainer.appendChild(singlePlotContainer);

        currentIndex++;
    }
};

const processCodeGeneratedPlots = (pyodide: any, plotPaths: string[]) => {
    const blobUrls = plotPaths.map((path) => {
        const imageData = pyodide.FS.readFile(path, { encoding: "binary" });
        const blob = new Blob([imageData.buffer], { type: "image/png" });
        return URL.createObjectURL(blob);
    });
    return blobUrls;
};

export const combineCodeSnippets = (codeSnippets: string[]): string => {
    const filteredCodeSnippets = codeSnippets.filter((snippet) => !snippet.startsWith("#"));
    return filteredCodeSnippets.join("\n");
};

export const Pyodide = (function () {
    let instance: any = null;
    let isInitializing = false;
    let isRunning = false;
    let useUploadedDataset = false;
    let uploadedDataset: string = "";
    const queue: {
        code: string;
        resolve: (consoleOutput: {
            success: boolean;
            data: string;
            variable: any;
            plots?: string[];
        }) => void;
        reject: (reason?: any) => void;
    }[] = [];

    async function createInstance(): Promise<any> {
        if (isInitializing || instance) {
            return instance;
        }
        isInitializing = true;
        instance = await window.loadPyodide({
            indexURL: "https://cdn.jsdelivr.net/pyodide/v0.24.1/full/",
            packages: ["numpy", "pandas", "matplotlib", "scipy", "scikit-learn"],
        });
        await instance.loadPackage("micropip");
        const micropip = instance.pyimport("micropip");
        await micropip.install("seaborn");
        isInitializing = false;
        return instance;
    }

    return {
        getInstance: async function () {
            if (!instance) {
                return await createInstance();
            }
            return instance;
        },
        setDatasetSource: function (useUpload: boolean) {
            useUploadedDataset = useUpload;
        },
        saveUploadedDataset: function (dataset: string) {
            uploadedDataset = dataset;
        },
        runCode: async function (
            code: string
        ): Promise<{ success: boolean; data: string; variable: any; plots?: string[] }> {
            return new Promise(async (resolve, reject) => {
                const task = { code, resolve, reject };
                if (isRunning) {
                    queue.push(task);
                } else {
                    isRunning = true;
                    await this.executeTask(task);
                }
            });
        },
        executeTask: async function (task: (typeof queue)[0]) {
            const pyodide = await this.getInstance();
            try {
                await pyodide.loadPackagesFromImports(task.code);
                await pyodide.runPythonAsync(`
                    globals().clear()
                    import js
                    import io
                    import sys
                    from io import StringIO, BytesIO
                    import base64
                    sys.stdout = StringIO()

                    from pyodide.http import open_url
                `);
                let injectDatasetCode = "";
                if (useUploadedDataset) {
                    injectDatasetCode = injectUploadedDataset(task.code, uploadedDataset);
                } else {
                    injectDatasetCode = parseURLs(task.code);
                }
                const { parsedCode, plotPaths } = getCodeGeneratedPlots(injectDatasetCode);

                const lastLineVariable = await pyodide.runPythonAsync(parsedCode);
                let consoleOutput = await pyodide.runPythonAsync("sys.stdout.getvalue()");
                let plots;

                if (plotPaths.length > 0) {
                    plots = processCodeGeneratedPlots(pyodide, plotPaths);
                    const plotsMessage = `Plots generated: (${plotPaths.length}) - click the 'View Plots' button on the visualizer block to view the results.`;
                    consoleOutput = plotsMessage.concat(consoleOutput);
                }
                task.resolve({
                    success: true,
                    data: consoleOutput,
                    variable: lastLineVariable,
                    plots,
                });
            } catch (error) {
                task.resolve({ success: false, data: `${error}`, variable: null });
            } finally {
                if (queue.length > 0) {
                    this.executeTask(queue.shift()!);
                } else {
                    isRunning = false;
                }
            }
        },
    };
})();
