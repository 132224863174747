import React, { KeyboardEvent, SetStateAction, useContext, useEffect, useState } from "react";
import "../styles/ProblemSelection.css";
import { Badge, Button, Card, ConfigProvider, Modal, Spin, theme } from "antd";
import { axiosInstance } from "../helpers/nodeHelpers";
import { useNavigate } from "react-router-dom";
import ProblemContext from "../contexts/ProblemContext";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";
import { userSettingsType } from "./App";

interface ProblemInterface {
    id: number;
    title: string;
    problem_number: number;
    problem_status: string;
    date_submitted: string | null;
    attempt_number: number | null;
    completed_tasks: number;
    total_tasks: number;
    adminOnly: boolean;
}

interface ProblemSelectionProps {
    isAdmin: boolean;
    setIsAuthenticated: (isAuthenticated: boolean) => void;
    userSettings: userSettingsType;
    username: string;
}

const ProblemSelection = ({
    isAdmin,
    setIsAuthenticated,
    userSettings,
    username,
}: ProblemSelectionProps) => {
    const [problems, setProblems] = useState<ProblemInterface[]>([]);
    const [problem, setProblem] = useState<ProblemInterface>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loadingProblems, setLoadingProblems] = useState(true);
    const problemContext = useContext(ProblemContext);
    if (!problemContext) throw new Error("ProblemContext not found");
    const { setProblem_id } = problemContext;
    const navigate = useNavigate();

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const goToProblem = (problem_id: number) => {
        // Ensure they're trying to navigate to a valid problem
        const targetProblem = problems.find((problem) => problem.id === problem_id);
        if (!targetProblem) {
            return;
        }
        // Check if the problem has been started, if so, open decision modal, otherwise go straight to description
        if (targetProblem.problem_status === "not started") {
            navigate(`/problems/${problem_id}/description`);
        } else {
            // Open modal asking whether to review problem description or resume problem
            setProblem(targetProblem);
            setIsModalOpen(true);
        }
    };

    const handleLogout = async () => {
        try {
            const response = await axiosInstance.post(
                "/api/users/logout",
                {},
                { withCredentials: true }
            );
            setIsAuthenticated(false);
            setProblem_id(undefined);
            navigate("/login");
        } catch (error) {
            console.log("Logout error ->", error);
        }
    };

    const getBadgeProps = (problem: ProblemInterface) => {
        switch (problem.problem_status) {
            case "in-progress":
                return {
                    text: "In-progress",
                    color: "yellow",
                    display: "block",
                };
            case "completed":
                return {
                    text: "Submitted",
                    color: "green",
                    display: "block",
                };
            default:
                return {
                    text: "",
                    color: "",
                    display: "none",
                };
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>, id: number) => {
        if (e.key === "Enter") {
            goToProblem(id);
        }
    };

    useEffect(() => {
        // Perform an axios request to get the problems
        const fetchProblems = async () => {
            try {
                const res = await axiosInstance.get("/api/problems/get-problems", {
                    withCredentials: true,
                });
                setProblems(res.data);
                setLoadingProblems(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchProblems();
    }, []);

    return (
        <div className="mainContainer">
            <Card className="problemListContainer" title={<h2>Problems</h2>}>
                <ConfigProvider
                    theme={{
                        algorithm: theme.darkAlgorithm,
                    }}
                >
                    {problems.map((problem) => {
                        if (problem.adminOnly) {
                            if (!isAdmin) {
                                return null;
                            }
                        }
                        const date_submitted = new Date(problem.date_submitted || "");
                        const formattedDate = date_submitted.toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        });
                        const badgeProps = getBadgeProps(problem);
                        return (
                            <Badge.Ribbon
                                key={problem.id}
                                text={badgeProps.text}
                                color={badgeProps.color}
                                style={{ display: badgeProps.display }}
                            >
                                <Card
                                    className="problemCard"
                                    onClick={() => goToProblem(problem.id)}
                                    onKeyDown={(e) => handleKeyDown(e, problem.id)}
                                    aria-label={`View Problem ${problem.problem_number}`}
                                    tabIndex={0}
                                    hoverable
                                >
                                    {`Problem ${problem.problem_number} - ${problem.title}`}
                                    {problem.attempt_number !== null && (
                                        <p className="attemptNumberText">
                                            {problem.problem_status === "completed"
                                                ? `${problem.attempt_number} attempts`
                                                : `Attempt Number: ${problem.attempt_number + 1}`}
                                        </p>
                                    )}
                                    {problem.problem_status !== "not started" && (
                                        <div className="problemSubmittedDateContainer">
                                            {problem.total_tasks > 0 &&
                                                (!userSettings ||
                                                    userSettings?.taskbar_enabled) && (
                                                    <span className="tasksCompletedText">
                                                        {`${problem.completed_tasks}/${problem.total_tasks} Tasks completed`}
                                                    </span>
                                                )}
                                            {problem.problem_status === "completed" && (
                                                <span className="submittedDateText">
                                                    {`Date Submitted: ${formattedDate}`}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </Card>
                            </Badge.Ribbon>
                        );
                    })}
                </ConfigProvider>
                {loadingProblems && (
                    <div className="loadingSpinnerContainer">
                        <Spin />
                    </div>
                )}
            </Card>
            <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
                <div className="problemSelectionLogoutBtn">
                    <h4>
                        Current user: <span className="usernameBold">{username}</span>
                    </h4>
                    <Button onClick={handleLogout}>Logout</Button>
                </div>
            </ConfigProvider>
            <Modal
                className="reviewDescriptionOrResumeProblemModal"
                title={problem && `Problem ${problem.problem_number} - ${problem.title}`}
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                style={{ textAlign: "center" }}
                centered
            >
                <div className="customModalBody">
                    <p className="modalParagraphText">
                        {`Review the problem description or
                        ${
                            problem?.problem_status === "completed"
                                ? "view your submission"
                                : "resume the problem"
                        }?`}
                    </p>
                    {problem && (
                        <div className="modalButtonContainer">
                            <Button onClick={() => navigate(`/problems/${problem.id}/description`)}>
                                Review Description
                            </Button>
                            {problem.problem_status === "completed" ? (
                                <Button
                                    onClick={() => navigate(`/problems/${problem.id}/submission`)}
                                >
                                    View Submission
                                </Button>
                            ) : (
                                <Button onClick={() => navigate(`/problems/${problem.id}`)}>
                                    Resume Problem
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default ProblemSelection;
