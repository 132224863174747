import { Button, Col, Image, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../helpers/nodeHelpers";
import { DeleteOutlined } from "@ant-design/icons";
import { BACKEND_URL } from "../config";
import { debouncedAutoSave } from "./editor";

interface ImageData {
    id: number;
    image_path: string;
    image_title: string;
    is_temporary?: boolean;
}

interface ImageGalleryModalProps {
    setIsImageGalleryOpen: (isImageGalleryOpen: boolean) => void;
    isImageGalleryOpen: boolean;
    setStoredImages: React.Dispatch<React.SetStateAction<ImageData[] | null>>;
    storedImages: ImageData[] | null;
}

const ImageGalleryModal = ({
    isImageGalleryOpen,
    setIsImageGalleryOpen,
    setStoredImages,
    storedImages,
}: ImageGalleryModalProps) => {
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get("/api/images/get-images", {
                    withCredentials: true,
                });
                const data = response.data;
                setStoredImages(data);
            } catch (error) {
                console.error("Error fetching images", error);
            }
        };

        fetchData();
    }, []);

    const deleteImage = async (image_id: number) => {
        try {
            const response = await axiosInstance.delete(`/api/images/delete-image/`, {
                data: { image_id },
                withCredentials: true,
            });
            setStoredImages((prevImages) => {
                if (prevImages) {
                    return prevImages.filter((image) => image.id !== image_id);
                }
                return prevImages;
            });
            debouncedAutoSave(
                "delete_image",
                {
                    deleted_image_id: image_id,
                    image_count: storedImages?.length || 0,
                    images: storedImages,
                },
                true
            );
        } catch (error) {
            console.error("Error deleting image", error);
        }
    };

    const handleCloseImageGallery = () => {
        setIsImageGalleryOpen(false);
        debouncedAutoSave(
            "close_image_gallery",
            { image_count: storedImages?.length || 0, images: storedImages },
            true
        );
    };

    useEffect(() => {
        if (isImageGalleryOpen) {
            debouncedAutoSave(
                "open_image_gallery",
                { image_count: storedImages?.length || 0, images: storedImages },
                true
            );
        }
    }, [isImageGalleryOpen]);

    return (
        <Modal
            open={isImageGalleryOpen}
            className="imageGalleryModal"
            centered
            width={"40%"}
            onCancel={handleCloseImageGallery}
            zIndex={1001}
            footer={[
                <Button key="close" onClick={handleCloseImageGallery} type="primary" danger>
                    Close
                </Button>,
            ]}
        >
            <h1>My Saved Images</h1>

            <Image.PreviewGroup>
                <Row gutter={[16, 16]}>
                    {storedImages ? (
                        storedImages.map((image, index) => {
                            return (
                                <Col span={6} className="imageGalleryItem" key={index}>
                                    <Image
                                        style={{ zIndex: 1002 }}
                                        src={
                                            image.is_temporary
                                                ? image.image_path
                                                : `${BACKEND_URL}${image.image_path}`
                                        }
                                        alt={image.image_title}
                                        title="Click to view full image"
                                    />
                                    <Button
                                        className="deleteButton"
                                        shape="circle"
                                        icon={<DeleteOutlined color="red" />}
                                        onClick={() => deleteImage(image.id)}
                                    ></Button>
                                    <div className="gallery-caption">{image.image_title}</div>
                                </Col>
                            );
                        })
                    ) : (
                        <p className="noDataFoundMessage">
                            No saved images found. Next time you generate a plot make sure to click
                            the 'Save Image' button below it.
                        </p>
                    )}
                </Row>
            </Image.PreviewGroup>
        </Modal>
    );
};

export default ImageGalleryModal;
