import { ConsoleViewerControl } from "../ReteControls/ConsoleViewerControls";
import React, { useState, useEffect } from "react";
import "../../styles/ConsoleViewer.css";
import { preventZoomOnScrollableElements } from "../../helpers/nodeHelpers";
import { Drag } from "rete-react-plugin";
import { Spin } from "antd";

const ConsoleViewerComponent = (props: { data: ConsoleViewerControl }) => {
    useEffect(() => {
        const consoleContainers = document.querySelectorAll(".console");
        preventZoomOnScrollableElements(consoleContainers);
    }, []);

    const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
    };

    return (
        <div className="consoleOutputContainer">
            <Spin
                wrapperClassName="consoleLoadingOverlay"
                tip="Processing changes..."
                spinning={props.data.isLoading}
                size="large"
            >
                <div
                    className="console"
                    onWheel={(e) => {
                        e.stopPropagation;
                    }}
                    onPointerDown={handleMouseDown}
                >
                    <pre>{props.data.currentConsole}</pre>
                </div>
            </Spin>
        </div>
    );
};

export default ConsoleViewerComponent;
