interface Imports {
    libraryName: string;
    importStatementArray: string[];
}

interface ImportConversion {
    [key: string]: string;
}

const baseImportConversion: ImportConversion = {
    Pandas: "import pandas as pd",
    Numpy: "import numpy as np",
    "Sci-Py": "import scipy as sp",
    "Scikit-Learn": "import sklearn as sk",
    Matplotlib: "import matplotlib.pyplot as plt",
    Seaborn: "import seaborn as sns",
};

export class ImportPackageCode {
    public currentImports: Imports[] = [];
    constructor() {}

    addBaseImport(libraryName: string) {
        let importStatementArray: string[] = [];
        if (libraryName in baseImportConversion) {
            importStatementArray.push(baseImportConversion[libraryName]);
        } else {
            importStatementArray.push(`import ${libraryName}`);
        }
        this.currentImports.push({ libraryName, importStatementArray });
    }

    removeBaseImport(libraryName: string) {
        const index = this.currentImports.findIndex((i) => i.libraryName === libraryName);
        if (index !== -1) {
            this.currentImports.splice(index, 1);
        }
    }

    addSpecificImport(libraryName: string, importStatement: string) {
        const index = this.currentImports.findIndex((i) => i.libraryName === libraryName);
        if (index !== -1) {
            const targetImport = this.currentImports[index];
            for (const specificImport of targetImport.importStatementArray) {
                if (specificImport === importStatement) {
                    return;
                }
            }
            targetImport.importStatementArray.push(importStatement);
            if (
                targetImport.importStatementArray.length === 2 &&
                targetImport.importStatementArray[0] === baseImportConversion[libraryName]
            ) {
                this.removeSpecificImport(libraryName, baseImportConversion[libraryName]);
            }
        }
    }

    removeSpecificImport(libraryName: string, importStatement: string) {
        const index = this.currentImports.findIndex((i) => i.libraryName === libraryName);
        if (index !== -1) {
            if (this.currentImports[index].importStatementArray.length === 1) {
                this.removeBaseImport(libraryName);
                return;
            }
            const filteredImportStatements = this.currentImports[index].importStatementArray.filter(
                (i) => i !== importStatement
            );
            this.currentImports[index].importStatementArray = [...filteredImportStatements];
        }
    }

    removeAllSpecificImports() {
        for (let i = 0; i < this.currentImports.length; i++) {
            const libraryName = this.currentImports[i].libraryName;
            this.currentImports[i].importStatementArray = [baseImportConversion[libraryName]];
        }
    }

    combineImportStatements() {
        let codeArray: string[] = [];
        const baseImports = this.currentImports.map((i) => i.importStatementArray);
        for (const importStatement of baseImports) {
            codeArray = [...codeArray, ...importStatement];
        }
        return codeArray.join("\n") + "\n";
    }
}
