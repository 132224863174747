import { Checkbox, ConfigProvider, Divider, Flex, Select, Spin, theme } from "antd";
import { Col, InputNumber, Row, Slider, Space } from "antd";
import { TestTrainSplitControl } from "../../components/ReteControls/TestTrainSplitControls";
import React, { useEffect, useState } from "react";
import "../../styles/TestTrainSplit.css";
import { preventZoomOnScrollableElements } from "../../helpers/nodeHelpers";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { debouncedAutoSave } from "../editor";
const TestTrainSplitComponent = (props: { data: TestTrainSplitControl }) => {
    const [checkedList, setCheckedList] = useState<string[]>([]);
    const [targetVariable, setTargetVariable] = useState<string>(props.data.targetFeature);
    const [targetVariableOptions, setTargetVariableOptions] = useState<string[]>(
        props.data.df_columns
    );
    const [testSize, setTestSize] = useState<number | null>(props.data.testSize);

    const handleOnChangeFeatures = (e: CheckboxChangeEvent) => {
        const newFeature = e.target.value;
        const isChecked = e.target.checked;
        const actionType = isChecked ? "select_feature" : "deselect_feature";
        const updatedCheckedList = isChecked
            ? [...checkedList, newFeature]
            : checkedList.filter((item) => item !== newFeature);

        setCheckedList(updatedCheckedList);
        props.data.setSelectedFeatures(updatedCheckedList);
        debouncedAutoSave(actionType, {
            featureName: newFeature,
            AllSelectedFeatures: updatedCheckedList,
        });
    };

    const handleOnChangeTarget = (value: string) => {
        props.data.setTargetVariable(value);
        setTargetVariable(value);
        debouncedAutoSave("set_target_variable", { targetVariable: value });
    };

    const HandleOnTestSizeChange = (newValue: number | null) => {
        if (newValue) {
            props.data.setTestSize(newValue);
        }
        setTestSize(newValue);
        debouncedAutoSave("set_test_size_ratio", { testSize: newValue });
    };

    useEffect(() => {
        setTestSize(props.data.testSize);
    }, [props.data.testSize]);

    useEffect(() => {
        setTargetVariable(props.data.targetFeature || "Choose Variable");
    }, [props.data.targetFeature]);

    useEffect(() => {
        setCheckedList(props.data.selectedFeatures);
    }, [props.data.selectedFeatures]);

    useEffect(() => {
        const newOptions = props.data.df_columns.filter(
            (option) => !checkedList.includes(option) || checkedList.length === 0
        );
        setTargetVariableOptions(newOptions);
    }, [props.data.df_columns, checkedList]);

    useEffect(() => {
        const listContainers = document.querySelectorAll(".featuresCheckboxList");
        preventZoomOnScrollableElements(listContainers);
    }, []);

    return (
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm,
            }}
        >
            <Spin spinning={props.data.isLoading}>
                <div>
                    <Divider className="topBottomDivider" />
                    <Flex
                        gap={".5rem"}
                        className="mainControlContainer"
                        onPointerDown={(e) => e.stopPropagation()}
                    >
                        <div className="selectFeaturesContainer">
                            <h4 className="testTrainSplitHeader">Features</h4>
                            <div className="featuresCheckboxList">
                                {props.data.df_columns.map((option, index) => {
                                    const disabled = option === targetVariable;
                                    const isChecked = checkedList.includes(option);
                                    const title = disabled
                                        ? "Target variable cannot be a feature"
                                        : isChecked
                                        ? "Remove feature"
                                        : "Add feature";
                                    return (
                                        <Checkbox
                                            name={`select ${title} feature`}
                                            key={index}
                                            value={option}
                                            title={title}
                                            disabled={disabled}
                                            onChange={handleOnChangeFeatures}
                                            checked={
                                                disabled ? false : checkedList.includes(option)
                                            }
                                        >
                                            {option}
                                        </Checkbox>
                                    );
                                })}
                            </div>
                            {props.data.errorMessages.length > 0 && (
                                <div className="featuresErrorWindow">
                                    {props.data.errorMessages.map((message, index) => (
                                        <p key={index} style={{ fontStyle: "italic" }}>
                                            {`> `}
                                            {message}
                                        </p>
                                    ))}
                                </div>
                            )}
                        </div>

                        <Divider type="vertical" style={{ height: "100%", marginInline: "0" }} />

                        <div className="selectTargetContainer">
                            <div className="halfContainer targetVariableContainer">
                                <h4 className="testTrainSplitHeader">Target Variable</h4>
                                <Select
                                    className="selectDropDown"
                                    onChange={handleOnChangeTarget}
                                    value={targetVariable}
                                    tabIndex={0}
                                    listHeight={800}
                                    dropdownStyle={{ width: "max-content", minWidth: "175px" }}
                                    options={targetVariableOptions.map((option) => {
                                        let disabled = option === targetVariable;
                                        return {
                                            value: option,
                                            disabled,
                                            title: disabled
                                                ? `${option} already selected`
                                                : `Set ${option} as target variable`,
                                        };
                                    })}
                                ></Select>
                            </div>

                            <Divider style={{ margin: 0 }} />

                            <div className="halfContainer testSizeRatioContainer">
                                <h4 className="testTrainSplitHeader">Test Size Ratio</h4>
                                <div>
                                    <Row className="inputNumberRow">
                                        <InputNumber
                                            name="testSizeRatioInput"
                                            min={0.05}
                                            max={1}
                                            step={0.05}
                                            style={{ margin: "0 16px", width: "70px" }}
                                            value={testSize}
                                            onChange={HandleOnTestSizeChange}
                                            className="testSizeInput"
                                        />
                                    </Row>
                                    <Row>
                                        <Slider
                                            min={0.05}
                                            max={1}
                                            step={0.05}
                                            onChange={HandleOnTestSizeChange}
                                            defaultValue={testSize!}
                                            value={typeof testSize === "number" ? testSize : 0}
                                            style={{ width: "100%" }}
                                            tooltip={{
                                                placement: "bottom",
                                                autoAdjustOverflow: false,
                                                className: "sliderTooltip",
                                            }}
                                        />
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Flex>
                    <Divider className="topBottomDivider" />
                </div>
            </Spin>
        </ConfigProvider>
    );
};

export default TestTrainSplitComponent;
