import { ProblemInterface } from "../components/EditorOverlayContainer";
import { ProblemProps } from "../providers/ProblemProvider";
import React from "react";

export interface ProblemContextType {
    setProblem_id: React.Dispatch<React.SetStateAction<number | undefined>>;
    tasks: ProblemProps[];
    setTasks: React.Dispatch<React.SetStateAction<ProblemProps[]>>;
    problem: ProblemInterface | undefined;
    setProblem: React.Dispatch<React.SetStateAction<ProblemInterface | undefined>>;
    currentTask: number;
    markTaskAsCompleted: (task_id: string) => Promise<void>;
    removeTaskFromCompleted: (task_id: string) => Promise<void>;
}

const ProblemContext = React.createContext<ProblemContextType | null>(null);

export default ProblemContext;
