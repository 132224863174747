import React, { useEffect, useRef } from "react";
import Prism from "prismjs";
import "prismjs/components/prism-python";
import "prismjs/themes/prism-okaidia.css";

type MarkdownRendererProps = {
    content: string;
};

const MarkdownRenderer = ({ content }: MarkdownRendererProps) => {
    useEffect(() => {
        Prism.highlightAll();
    }, [content]);
    return <div className="moduleTextContent" dangerouslySetInnerHTML={{ __html: content }}></div>;
};

export default MarkdownRenderer;
