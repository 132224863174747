import { Steps, Button, ConfigProvider, theme, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import ProblemContext from "../contexts/ProblemContext";
import "../styles/Roadmap.css";
import {
    CheckCircleTwoTone,
    CheckSquareTwoTone,
    MinusCircleOutlined,
    MinusSquareOutlined,
} from "@ant-design/icons";

interface RoadmapProps {
    setIsAuthenticated: (isAuthenticated: boolean) => void;
    collapsed: boolean;
}

const Roadmap = ({ setIsAuthenticated, collapsed }: RoadmapProps) => {
    const [highestCompletedTask, setHighestCompletedTask] = useState<number>(0);
    const [delayRender, setDelayRender] = useState<boolean>(collapsed);
    const problemContext = useContext(ProblemContext);

    if (!problemContext) {
        throw new Error("ProblemContext must be used within a ProblemProvider");
    }

    const { currentTask, tasks, problem } = problemContext;
    const items = tasks?.map((task) => {
        const { id, ...rest } = task;
        return { ...rest } || [];
    });

    useEffect(() => {
        const handlePointerDown = (e: Event) => {
            e.stopPropagation();
            const currentTarget = e.currentTarget;
        };
        const subSteps = document.querySelectorAll(".subStep");
        subSteps.forEach((task) => {
            task.addEventListener("pointerdown", handlePointerDown);
        });

        return () => {
            subSteps.forEach((task) => {
                task.removeEventListener("pointerdown", handlePointerDown);
            });
        };
    }, []);

    useEffect(() => {
        setHighestCompletedTask(currentTask === -1 ? tasks.length : currentTask);
    }, [currentTask, tasks]);

    useEffect(() => {
        if (!collapsed) {
            const timer = setTimeout(() => {
                setDelayRender(collapsed);
            }, 300);
            return () => {
                clearTimeout(timer);
            };
        } else {
            setDelayRender(collapsed);
        }
    }, [collapsed]);

    return (
        <div className={`sidebarContainer ${collapsed ? "sidebarCollapsed" : ""}`}>
            {!delayRender && (
                <>
                    <h2 className="roadmapHeader">Problem {problem?.problem_number} Tasks</h2>
                    <div className="taskProgressContainer">
                        <p>
                            {tasks.reduce((a, b) => {
                                if (b.task_status === "completed") {
                                    return a + 1;
                                }
                                return a;
                            }, 0)}
                            /{tasks.length}{" "}
                            {highestCompletedTask === tasks.length
                                ? "All tasks completed"
                                : `Tasks completed`}
                        </p>
                        <progress
                            value={tasks.reduce((a, b) => {
                                if (b.task_status === "completed") {
                                    return a + 1;
                                }
                                return a;
                            }, 0)}
                            max={tasks.length}
                            id="taskProgress"
                        ></progress>
                    </div>
                    <Steps
                        className="roadmapSteps"
                        current={currentTask}
                        direction="vertical"
                        labelPlacement="horizontal"
                    >
                        {items.map((item, index) => (
                            <Steps.Step
                                key={index}
                                title={item.title}
                                description={item.description}
                                icon={
                                    <Tooltip
                                        title={
                                            item.task_status === "completed"
                                                ? "Complete"
                                                : "Incomplete"
                                        }
                                    >
                                        {item.task_status === "completed" ? (
                                            <CheckCircleTwoTone twoToneColor={"lightgreen"} />
                                        ) : (
                                            <div
                                                className="unfinishedTaskIcon"
                                                style={{
                                                    color: `${
                                                        currentTask === index ? "grey" : undefined
                                                    }`,
                                                }}
                                            ></div>
                                        )}
                                    </Tooltip>
                                }
                            />
                        ))}
                    </Steps>
                </>
            )}
        </div>
    );
};

export default Roadmap;
