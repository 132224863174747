import { ClassicPreset } from "rete";

export class ConsoleViewerControl extends ClassicPreset.Control {
    constructor(public currentConsole: string, public isLoading: boolean) {
        super();
    }

    setValue(console: string) {
        this.currentConsole = console;
    }

    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }
}
