import { Button, Divider, Input, InputRef, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { debouncedAutoSave, globalEditor, globalEngine } from "./editor";
import "../styles/VisualizerModal.css";
import { axiosInstance } from "../helpers/nodeHelpers";

const { confirm } = Modal;

interface ImageData {
    id: number;
    image_path: string;
    image_title: string;
    is_temporary?: boolean;
}

interface VisualizerModalProps {
    isVisualizerOpen: boolean;
    generatedPlots: string[];
    setIsVisualizerOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsImageGalleryOpen: React.Dispatch<React.SetStateAction<boolean>>;
    plotType: string;
    setStoredImages: React.Dispatch<React.SetStateAction<ImageData[] | null>>;
}

interface plotTypeToTitleProps {
    [key: string]: string;
}

const plotTypeToTitle: plotTypeToTitleProps = {
    heatmap: "Heatmap",
    bar_chart: "Bar Chart",
    histogram: "Histogram",
    scatter: "Scatter Plot",
};

export const fetchBlob = async (blobUrl: string) => {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    return blob;
};

export const blobToBase64 = (blob: Blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

const VisualizerModal = ({
    isVisualizerOpen,
    generatedPlots,
    setIsVisualizerOpen,
    setIsImageGalleryOpen,
    plotType,
    setStoredImages,
}: VisualizerModalProps) => {
    const [localPlotType, setLocalPlotType] = React.useState(plotType);
    const [title, setTitle] = useState("");
    const [imageLoading, setImageLoading] = useState(false);
    const [disableSaveImage, setDisableSaveImage] = useState(true);

    const titleRef = useRef<string>(title);
    const titleInputRef = useRef<InputRef>(null);

    const saveImageToDb = async (imageUrl: string) => {
        const blob = await fetchBlob(imageUrl);
        const base64 = await blobToBase64(blob);

        axiosInstance
            .post(
                "/api/images/save-image",
                { image: base64, imageTitle: titleRef.current },
                { withCredentials: true }
            )
            .then((response) => {
                debouncedAutoSave("save_plot_image", { imageTitle: titleRef.current }, true);
                setDisableSaveImage(true);
                setStoredImages((prevImages) => {
                    if (prevImages) {
                        return [
                            ...prevImages,
                            {
                                id: response.data.image_id,
                                image_path: imageUrl,
                                image_title: titleRef.current,
                                is_temporary: true,
                            },
                        ];
                    } else {
                        return [
                            {
                                id: response.data.image_id,
                                image_path: imageUrl,
                                image_title: titleRef.current,
                                is_temporary: true,
                            },
                        ];
                    }
                });
            })
            .catch((error) => {
                console.error("Error uploading image", error);
            })
            .finally(() => {
                setImageLoading(false);
            });
    };

    const updateTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newTitle = e.target.value;
        setTitle(newTitle);
        titleRef.current = newTitle;
    };

    const openSavePrompt = (defaultTitle: string, imageUrl: string) => {
        setImageLoading(true);
        setTitle(defaultTitle);
        confirm({
            title: "Add a title before saving",
            content: (
                <div>
                    <h4>Image Title</h4>
                    <Input ref={titleInputRef} defaultValue={defaultTitle} onChange={updateTitle} />
                </div>
            ),
            onOk() {
                saveImageToDb(imageUrl);
            },
            onCancel() {
                setImageLoading(false);
            },
        });
        setTimeout(() => {
            if (titleInputRef.current) {
                titleInputRef.current.focus();
                titleInputRef.current.select();
            }
        }, 100);
    };

    useEffect(() => {
        setLocalPlotType(plotType);
        if (plotType !== "") {
            const updateVisualizerNodes = async () => {
                const visualizerNodes = globalEditor
                    .getNodes()
                    .filter((node) => node.getType() === "Visualizer");
                for (const visualizer of visualizerNodes) {
                    const data = await globalEngine.fetch(visualizer.id);
                }
            };
            updateVisualizerNodes();
            setDisableSaveImage(false);
        }
    }, [generatedPlots]);

    return (
        <Modal
            centered
            width={"40%"}
            zIndex={1000}
            open={isVisualizerOpen}
            className="visualizerModal"
            onCancel={() => setIsVisualizerOpen(false)}
            footer={[
                <Button
                    key="close"
                    onClick={() => setIsVisualizerOpen(false)}
                    type="primary"
                    danger
                >
                    Close
                </Button>,
            ]}
        >
            <h2>Generated Plot(s)</h2>
            <div className="plotsContainer">
                {generatedPlots.length === 0 && (
                    <p className="noDataFoundMessage">
                        No plot(s) generated, please run the program to generate a plot.
                    </p>
                )}
                {generatedPlots.map((plot, index) => {
                    return (
                        <div className="singlePlotContainer" key={index}>
                            <h3>{plotTypeToTitle[localPlotType]}</h3>
                            <img src={plot} alt={`Generated Plot ${index + 1}`} />
                            <Button
                                type="primary"
                                className="saveBtn"
                                loading={imageLoading}
                                disabled={disableSaveImage}
                                onClick={() => openSavePrompt(plotTypeToTitle[localPlotType], plot)}
                            >
                                {disableSaveImage
                                    ? "Image saved!"
                                    : imageLoading
                                    ? "Saving"
                                    : "Save Image"}
                            </Button>
                        </div>
                    );
                })}

                <Divider />
                <Button type="primary" onClick={() => setIsImageGalleryOpen(true)}>
                    View Saved Images
                </Button>
            </div>
        </Modal>
    );
};

export default VisualizerModal;
