import { ConsoleViewerControl } from "../ReteControls/ConsoleViewerControls";
import { ClassicPreset } from "rete";
import { Pyodide, combineCodeSnippets } from "../../helpers/pyodideHelpers";
import { DataOutput, PreviousNodeData } from "components/ReteInterfaces/SharedInterfaces";
import { delayMs } from "../../helpers/nodeHelpers";

export class ConsoleViewer extends ClassicPreset.Node<
    { input: ClassicPreset.Socket },
    { output: ClassicPreset.Socket },
    { display: ConsoleViewerControl }
> {
    width = 700;
    height = 400;
    public currentConsole: string = "# Connect code generating blocks to view console output";
    public isLoading: boolean = false;
    constructor(
        socket: ClassicPreset.Socket,
        private update: (type: "node", asset: ConsoleViewer) => void
    ) {
        super("Console Output");
        this.addControl("display", new ConsoleViewerControl(this.currentConsole, this.isLoading));
        this.addInput("input", new ClassicPreset.Input(socket, "Code input"));
    }
    getType() {
        return "ConsoleViewer";
    }
    getDisplayName() {
        return "Console Viewer";
    }
    #toggleLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
        this.controls.display.setIsLoading(isLoading);
        this.update("node", this);
    };

    async data(inputs: { input: DataOutput[] }): Promise<{ output: DataOutput }> {
        const previousNodes = inputs.input?.[0]?.previousNodes || [];
        const codeInput: string[] = inputs.input?.[0].code || [];
        let totalCode = combineCodeSnippets(codeInput);
        let display = [this.currentConsole];

        if (totalCode) {
            this.#toggleLoading(true);
            await delayMs(10);
            let consoleOutput = await Pyodide.runCode(totalCode);
            if (consoleOutput.success) {
                consoleOutput.data = "[Executed successfully ✓]\n".concat(consoleOutput.data);
            }
            this.controls.display.setValue(consoleOutput.data);
            this.#toggleLoading(false);
            display = [consoleOutput.data];
        } else {
            this.controls.display.setValue(
                inputs.input ? "[No runnable code detected]" : this.currentConsole
            );
            this.update("node", this);
        }

        const currentNodeData: PreviousNodeData = {
            ...this,
            connectedPort: this.outputs.output!,
        };
        const output = { code: codeInput, previousNodes: [...previousNodes, currentNodeData] };

        return { output };
    }
}
