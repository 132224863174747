import { DataOutput, PreviousNodeData } from "components/ReteInterfaces/SharedInterfaces";
import { CodeViewerControl } from "../ReteControls/CodeViewerControls";
import { ClassicPreset } from "rete";
import { loadCollectedImportStatements } from "../../helpers/nodeHelpers";
export class CodeViewer extends ClassicPreset.Node<
    { main: ClassicPreset.Socket },
    { output: ClassicPreset.Socket },
    { display: CodeViewerControl }
> {
    width = 700;
    height = 400;
    private currentCode: string = "Connect a block";
    constructor(
        socket: ClassicPreset.Socket,
        private update: (type: "control" | "node", asset: CodeViewerControl | CodeViewer) => void
    ) {
        super("Code Viewer");
        this.addControl("display", new CodeViewerControl(this.currentCode));
        this.addInput("main", new ClassicPreset.Input(socket, "Code input"));
    }
    getType() {
        return "CodeViewer";
    }
    getDisplayName() {
        return "Code Viewer";
    }
    data(inputs: { main: DataOutput[] }): { output: DataOutput } {
        const previousNodes = inputs.main?.[0]?.previousNodes || [];
        const importStatement: string = loadCollectedImportStatements(previousNodes);

        const mainData = inputs.main?.[0]?.code || ["Connect a block"];
        if (inputs.main && importStatement) {
            mainData[0] = importStatement;
        }
        const displayValue: string = mainData.join("\n");
        this.controls.display.setValue(displayValue);

        const currentNodeData: PreviousNodeData = {
            ...this,
            connectedPort: this.outputs.output!,
        };
        const output = { code: mainData, previousNodes: [...previousNodes, currentNodeData] };
        this.update("node", this);
        return {
            output,
        };
    }
}
