import React, { useContext, useEffect } from "react";
import ProblemContext from "../contexts/ProblemContext";
import { axiosInstance } from "../helpers/nodeHelpers";
import { useNavigate } from "react-router-dom";

type Props = {
    setIsAuthenticated: (isAuthenticated: boolean) => void;
};

const LogoutComponent = ({ setIsAuthenticated }: Props) => {
    const navigate = useNavigate();
    const problemContext = useContext(ProblemContext);
    if (!problemContext) return <div>ProblemContext not found</div>;

    const { setProblem_id } = problemContext;

    const handleLogout = async () => {
        try {
            const response = await axiosInstance.post(
                "/api/users/logout",
                {},
                { withCredentials: true }
            );
            setIsAuthenticated(false);
            setProblem_id(undefined);
            navigate("/login");
        } catch (error) {
            console.log("Logout error ->", error);
        }
    };

    useEffect(() => {
        handleLogout();
    }, [problemContext]);

    return <div>Logging out...</div>;
};

export default LogoutComponent;
