import { Button, Layout, Tooltip } from "antd";
import Sider from "antd/es/layout/Sider";
import React, { Dispatch, SetStateAction, useState } from "react";
import CodePreview from "./CodePreview";
import ConsoleViewer from "./ConsoleViewer";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import { handleZoomToFit } from "./EditorButtonPanel";
import { CodePath } from "./MainEditor";

interface RightSideBarProps {
    codePreview: string;
    runProgram: boolean;
    setRunProgram: Dispatch<SetStateAction<boolean>>;
    setGeneratedPlots: Dispatch<SetStateAction<string[]>>;
    rightRef: React.RefObject<HTMLDivElement>;
    codePaths: CodePath[];
    consoleText: string;
    setConsoleText: Dispatch<SetStateAction<string>>;
}

const RightSideBar = (props: RightSideBarProps) => {
    const [collapsed, setCollapsed] = useState(false);
    const {
        codePreview,
        runProgram,
        setRunProgram,
        setGeneratedPlots,
        rightRef,
        consoleText,
        setConsoleText,
    } = props;
    const [tooltipOpen, setTooltipOpen] = useState<boolean | undefined>();

    const resetClosedTooltip = () => {
        setTooltipOpen(false);
        setTimeout(() => {
            setTooltipOpen(undefined);
        }, 10);
    };

    const handleCollapse = () => {
        setCollapsed((prev) => !prev);
        resetClosedTooltip();
        setTimeout(() => {
            handleZoomToFit();
        }, 0);
    };

    return (
        <Layout
            ref={rightRef}
            className={`rightSideContainer ${collapsed ? "siderCollapsed" : ""}`}
        >
            <Sider
                className={`rightSiderContainer ${collapsed ? "siderCollapsed" : ""}`}
                collapsed={collapsed}
                onCollapse={() => setCollapsed(!collapsed)}
                collapsible
                trigger={null}
            >
                <Tooltip
                    placement="left"
                    title={collapsed ? "Expand Sidebar" : "Collapse Sidebar"}
                    open={tooltipOpen}
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0.5}
                >
                    <Button
                        className="rightSiderCollapseBtn topCollapseBtn"
                        onClick={handleCollapse}
                        icon={!collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
                    ></Button>
                </Tooltip>
                {!collapsed && (
                    <>
                        <CodePreview codePreview={codePreview} />
                        <ConsoleViewer
                            codePreview={codePreview}
                            runProgram={runProgram}
                            setRunProgram={setRunProgram}
                            setGeneratedPlots={setGeneratedPlots}
                            codePaths={props.codePaths}
                            consoleText={consoleText}
                            setConsoleText={setConsoleText}
                        />
                    </>
                )}

                {collapsed && (
                    <>
                        <p className="sideBarPlaceHolderText">
                            Expand to view code preview and console output.
                        </p>
                    </>
                )}
                <Tooltip
                    placement="left"
                    title={collapsed ? "Expand Sidebar" : "Collapse Sidebar"}
                    open={tooltipOpen}
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0.5}
                >
                    <Button
                        className="rightSiderCollapseBtn bottomCollapseBtn"
                        onClick={handleCollapse}
                        icon={!collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
                    ></Button>
                </Tooltip>
            </Sider>
        </Layout>
    );
};

export default RightSideBar;
