import { Button, Drawer } from "antd";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import ProblemDescription from "./ProblemDescription";
import "../styles/ProblemDescriptionDrawer.css";
import { ProblemInterface } from "./EditorOverlayContainer";
import { debouncedAutoSave } from "./editor";

const STARTING_DRAWER_HEIGHT = Math.round(window.innerHeight * 0.5);
let DRAWER_MIN_HEIGHT = Math.round(window.innerHeight * 0.25);
let DRAWER_MAX_HEIGHT = Math.round(window.innerHeight * 0.9);
const RESIZE_HANDLE_HEIGHT = 26;

interface ProblemDescriptionDrawerProps {
    leftColumnRef: React.RefObject<HTMLDivElement>;
    rightColumnRef: React.RefObject<HTMLDivElement>;
    isAdmin: boolean;
}

const ProblemDescriptionDrawer = ({
    leftColumnRef,
    rightColumnRef,
    isAdmin,
}: ProblemDescriptionDrawerProps) => {
    const [open, setOpen] = useState(false);
    const [displayHeightAdjustBar, setDisplayHeightAdjustBar] = useState(false);
    const [drawerHeight, setDrawerHeight] = useState(STARTING_DRAWER_HEIGHT);
    const [offsetHeight, setOffsetHeight] = useState(0);
    const [isResizing, setIsResizing] = useState(false);
    const [leftPosition, setLeftPosition] = useState(0);
    const [rightPosition, setRightPosition] = useState(0);
    const isResizingRef = useRef(isResizing);

    const updateWidths = () => {
        if (leftColumnRef.current) {
            setLeftPosition(leftColumnRef.current.offsetWidth);
        }
        if (rightColumnRef.current) {
            const rightRect = rightColumnRef.current.getBoundingClientRect();
            setRightPosition(document.body.offsetWidth - rightRect.left);
        }
    };

    const showDrawer = () => {
        setOpen(true);
        debouncedAutoSave("open_problem_description", {}, true);
    };

    const onClose = () => {
        const drawer = document.querySelector(".ant-drawer-body");
        drawer?.scrollTo(0, 0);
        setDisplayHeightAdjustBar(false);
        setOpen(false);
        debouncedAutoSave("close_problem_description", {}, true);
    };

    const startResizing = (e: MouseEvent) => {
        e.preventDefault();
        setIsResizing(true);
        isResizingRef.current = true;
        setOffsetHeight(e.clientY - drawerHeight);
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", stopResizing);
    };

    const stopResizing = () => {
        setIsResizing(false);
        isResizingRef.current = false;
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", stopResizing);
    };

    const handleMouseMove = (e: any) => {
        if (isResizingRef.current) {
            updateDrawerHeight(e.clientY - offsetHeight);
        }
    };

    const updateDrawerHeight = (newHeight: number) => {
        if (newHeight < DRAWER_MIN_HEIGHT) {
            setDrawerHeight(DRAWER_MIN_HEIGHT);
            return;
        } else if (newHeight > DRAWER_MAX_HEIGHT) {
            setDrawerHeight(DRAWER_MAX_HEIGHT);

            return;
        }
        setDrawerHeight(Math.round(newHeight));
    };

    useEffect(() => {
        const wrapper = document.querySelector(".ant-drawer-top > .ant-drawer-content-wrapper");
        const mask = document.querySelector(".ant-drawer-mask");
        if (!wrapper) return;
        if (!mask) return;

        if (isResizing) {
            wrapper.classList.add("no-transition");
            mask.classList.add("no-transition");
        } else {
            wrapper.classList.remove("no-transition");
            mask.classList.remove("no-transition");
        }

        return () => {
            wrapper.classList.remove("no-transition");
            mask.classList.remove("no-transition");
        };
    }, [isResizing]);

    useEffect(() => {
        const updateDrawerUponResize = () => {
            DRAWER_MIN_HEIGHT = Math.round(window.innerHeight * 0.25);
            DRAWER_MAX_HEIGHT = Math.round(window.innerHeight * 0.9);
            updateDrawerHeight(drawerHeight);
        };

        window.addEventListener("resize", updateDrawerUponResize);

        return () => {
            window.removeEventListener("resize", updateDrawerUponResize);
        };
    }, [drawerHeight]);

    useEffect(() => {
        const mask = document.querySelector(".ant-drawer-mask");
        if (mask && open && !mask.getAttribute("title")) {
            mask.setAttribute("title", "Click out here to close problem description");
        }
    }, [displayHeightAdjustBar, open]);

    useEffect(() => {
        const leftObserver = new ResizeObserver(updateWidths);
        const rightObserver = new ResizeObserver(updateWidths);

        if (leftColumnRef.current) {
            leftObserver.observe(leftColumnRef.current);
        }

        if (rightColumnRef.current) {
            rightObserver.observe(rightColumnRef.current);
        }

        return () => {
            if (leftColumnRef.current) {
                leftObserver.disconnect();
            }
            if (rightColumnRef.current) {
                rightObserver.disconnect();
            }
        };
    }, []);

    return (
        <div className="drawerOuterContainer">
            <Drawer
                placement="top"
                open={open}
                onClose={onClose}
                height={drawerHeight}
                style={{ maxHeight: `${DRAWER_MAX_HEIGHT}px`, minHeight: `${DRAWER_MIN_HEIGHT}px` }}
                styles={{
                    wrapper: {
                        left: leftPosition,
                        right: rightPosition,
                    },
                }}
                classNames={{
                    mask: "",
                    content: "problemDescriptionDrawerContent",
                    body: "problemDescriptionDrawerBody",
                }}
                className="problemDescriptionDrawer"
            >
                <ProblemDescription inDrawer={true} isAdmin={isAdmin} />
                <div
                    className="resizeBar problemDescriptionResizeBar"
                    onMouseDown={startResizing}
                    title="Drag to resize"
                ></div>
            </Drawer>
            <Button className="viewProblemDescButton" onClick={showDrawer}>
                View Problem Description
            </Button>
        </div>
    );
};

export default ProblemDescriptionDrawer;
