import { CodeViewerControl } from "../ReteControls/CodeViewerControls";
import React from "react";
import CodeMirror from "@uiw/react-codemirror";
import { EditorView } from "@uiw/react-codemirror";
import { python } from "@codemirror/lang-python";

const CodeDisplayElement = (props: { data: CodeViewerControl }) => {
    const { lineWrapping, editable } = EditorView;
    return (
        <CodeMirror
            onPointerDown={(e) => e.stopPropagation()}
            style={{
                cursor: "text",
                whiteSpace: "pre-line",
                overflow: "auto",
                height: "300px",
            }}
            value={props.data.currentCode}
            height="300px"
            maxWidth="100%"
            theme={"dark"}
            extensions={[python(), lineWrapping, editable.of(false)]}
            onWheel={(e) => {
                e.stopPropagation();
            }}
            onContextMenu={(e) => {
                e.stopPropagation();
            }}
        />
    );
};

export default CodeDisplayElement;
