import { DropdownListControl } from "../ReteControls/DropdownListControl";
import { ClassicPreset } from "rete";
import { DataOutput, PreviousNodeData } from "../ReteInterfaces/SharedInterfaces";
import { getPreviousNodes } from "../../helpers/nodeHelpers";
import { ImportPackageCode } from "../../helpers/ImportPackageClass";
import { ProblemContextType } from "../../contexts/ProblemContext";

export class PackageImporter extends ClassicPreset.Node<
    { input: ClassicPreset.Socket },
    { output: ClassicPreset.Socket },
    { dropdown: DropdownListControl }
> {
    height = 380;
    width = 180;
    selectedItems: string[] = [];
    ImportManager = new ImportPackageCode();
    task_id = "createLibraryImporter";
    private change: () => void;
    private update: (type: "node", node: ClassicPreset.Node) => void;

    constructor(
        socket: ClassicPreset.Socket,
        change: () => void,
        update: (type: "node", asset: ClassicPreset.Node) => void,
        context: ProblemContextType
    ) {
        super("Import Libraries");
        this.addInput("input", new ClassicPreset.Input(socket, "Input"));
        this.addOutput("output", new ClassicPreset.Output(socket, "Imported libraries"));
        const optionsForDropdown = [
            "Pandas",
            "Numpy",
            "Sci-Py",
            "Scikit-Learn",
            "Matplotlib",
            "Seaborn",
        ];
        const dropdownListControl = new DropdownListControl(
            optionsForDropdown,
            this.handleSelect.bind(this),
            this.selectedItems,
            this.alreadySelected.bind(this),
            this.handleRemove.bind(this),
            this.task_id,
            context
        );
        this.addControl("dropdown", dropdownListControl);
        this.change = change;
        this.update = update;
    }
    getType() {
        return "PackageImporter";
    }
    getDisplayName() {
        return "Package Importer";
    }
    #numSelectedItems(): number {
        return this.selectedItems.length;
    }

    #refresh() {
        this.update("node", this);
        this.change();
    }

    private alreadySelected(option: string): boolean {
        return this.selectedItems.includes(option);
    }

    handleSelect(option: string, updateState: (items: string[]) => void) {
        if (!this.selectedItems.includes(option)) {
            this.selectedItems.push(option);
            this.controls.dropdown.setSelectedItems([...this.selectedItems]);
            this.ImportManager.addBaseImport(option);
            updateState([...this.selectedItems]);
            this.#refresh();
        }
    }

    handleRemove(option: string, updateState: (items: string[]) => void) {
        const remainingItems = this.selectedItems.filter((item: string) => item !== option);
        this.selectedItems = [...remainingItems];
        this.controls.dropdown.setSelectedItems([...this.selectedItems]);
        this.ImportManager.removeBaseImport(option);
        updateState([...remainingItems]);
        this.#refresh();
    }

    #refreshImportManager() {
        this.ImportManager.currentImports = [];
        for (const item of this.selectedItems) {
            this.ImportManager.addBaseImport(item);
        }
    }

    data(inputs: { input?: DataOutput[] }): { output: DataOutput } {
        this.#refreshImportManager();
        const inputConnection = inputs.input?.[0];
        let code: string[] = [];
        const currentCode =
            this.#numSelectedItems() === 0
                ? ["# Import Libraries"]
                : [this.ImportManager.combineImportStatements()];
        if (inputConnection) {
            code = [...inputConnection.code];
        }
        code.push(...currentCode);

        // const previousNodes: PreviousNodeData[] = getPreviousNodes(Object.values(inputs));
        const previousNodes: PreviousNodeData[] = inputs.input?.[0].previousNodes || [];
        const currentNode: PreviousNodeData = { ...this, connectedPort: this.outputs.output! };
        const output = { code, previousNodes: [...previousNodes, currentNode] };

        return {
            output,
        };
    }
}
