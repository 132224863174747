import { Space, Switch } from "antd";
import React, { useState } from "react";
import { Drag } from "rete-react-plugin";
import "../../../styles/Models.css";
import { LinearRegressionSettings } from "../../ReteControls/Models/LinearRegressionSettings";
import { debouncedAutoSave } from "../../editor";

const LinearRegressionSettingsComponent = (props: { data: LinearRegressionSettings }) => {
    const {
        initialFitIntercept,
        initialCopyX,
        initialPositive,
        updateFitIntercept,
        updateCopyX,
        updatePositive,
    } = props.data;
    const [fitIntercept, setFitIntercept] = useState<boolean>(initialFitIntercept);
    const [copyX, setCopyX] = useState<boolean>(initialCopyX);
    const [positive, setPositive] = useState<boolean>(initialPositive);

    const handleFitInterceptChange = (checked: boolean) => {
        setFitIntercept(checked);
        updateFitIntercept(checked);
        debouncedAutoSave("set_fit_intercept", { fit_intercept: checked });
    };

    const handleCopyXChange = (checked: boolean) => {
        setCopyX(checked);
        updateCopyX(checked);
        debouncedAutoSave("set_copy_x", { copy_X: checked });
    };

    const handlePositiveChange = (checked: boolean) => {
        setPositive(checked);
        updatePositive(checked);
        debouncedAutoSave("set_force_positive_coefficients", {
            force_positive_coefficients: checked,
        });
    };

    return (
        <div className="linearRegressionSettingsContainer">
            <Drag.NoDrag>
                <Space size={"middle"} direction="vertical">
                    <div className="labelSwitchContainer">
                        <label>Include Intercept</label>
                        <Switch checked={fitIntercept} onChange={handleFitInterceptChange} />
                    </div>
                    <div className="labelSwitchContainer">
                        <label>Copy Input Data</label>
                        <Switch checked={copyX} onChange={handleCopyXChange} />
                    </div>
                    <div className="labelSwitchContainer">
                        <label>Force Positive Coefficients</label>
                        <Switch checked={positive} onChange={handlePositiveChange} />
                    </div>
                </Space>
            </Drag.NoDrag>
        </div>
    );
};

export default LinearRegressionSettingsComponent;
