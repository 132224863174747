import { ProblemContextType } from "../../contexts/ProblemContext";
import { ClassicPreset } from "rete";

export class DatasetImporterTextControls extends ClassicPreset.Control {
    constructor(
        public value: string,
        public useUploadedDataset: boolean,
        public onChange: (value: string) => void,
        public isValidCsvUrl: (url: string) => boolean,
        public setDatasetColumns: (datasetColumns: string[]) => void,
        public uploadDataset: (dataset: string, file: File | null) => void,
        public setDatasetSource: (useImported: boolean) => void,
        public context: ProblemContextType,
        public signalParseCsvComplete: () => void
    ) {
        super();
    }

    setValue(value: string) {
        this.value = value;
    }

    setUseUploadedDataset(useUploadedDataset: boolean) {
        this.useUploadedDataset = useUploadedDataset;
    }

    setupControls(controlData: any) {
        this.value = controlData.value;
        this.useUploadedDataset = controlData.useUploadedDataset;
        // If dataset was previously uploaded, there's no way to recover it so mark as incomplete task
        if (this.useUploadedDataset) {
            this.context.removeTaskFromCompleted("importDataset");
        }
    }
}
