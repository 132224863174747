import { ClassicPreset } from "rete";

export class LinearRegressionSettings extends ClassicPreset.Control {
    constructor(
        public initialFitIntercept: boolean,
        public initialCopyX: boolean,
        public initialPositive: boolean,
        public updateFitIntercept: (value: boolean) => void,
        public updateCopyX: (value: boolean) => void,
        public updatePositive: (value: boolean) => void
    ) {
        super();
    }

    setupControls(controlData: any) {
        this.initialFitIntercept = controlData.initialFitIntercept;
        this.initialCopyX = controlData.initialCopyX;
        this.initialPositive = controlData.initialPositive;
    }
}
