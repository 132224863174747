import lib from "@ant-design/icons";
import React, { useEffect } from "react";

type Props = {
    messages: string[];
    plotType: string;
};

const ErrorDisplay = ({ messages, plotType }: Props) => {
    const [localMessages, setLocalMessages] = React.useState<string[]>(messages);
    useEffect(() => {
        if (plotType === "") {
            setLocalMessages(["Select a plot type", ...messages]);
        } else {
            setLocalMessages(messages);
        }
    }, [plotType, messages]);

    return (
        <div className="errorDisplayContainer">
            {localMessages.length > 0 && (
                <ul>
                    {localMessages.map((message, index) => (
                        <li key={index}>{`> ${message}`}</li>
                    ))}
                </ul>
            )}
            {messages.length === 0 && plotType != "" && (
                <h3 className="successVisualizer">{">"} Run Program to view plots</h3>
            )}
        </div>
    );
};

export default ErrorDisplay;
