import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { axiosInstance } from "../helpers/nodeHelpers";
import PageLoadingMessage from "./TextComponents/PageLoadingMessage";
import ProblemContext from "../contexts/ProblemContext";
import { checkIfAuthenticated } from "./App";

interface ProtectedRouteProps {
    children: JSX.Element;
    isAuthenticated: boolean;
    isLoading: boolean;
    setIsAuthenticated: (isAuthenticated: boolean) => void;
    setIsAdmin: (isAdmin: boolean) => void;
    setUsername: (username: string) => void;
    setUserSettings: (userSettings: any) => void;
    setIsLoading: (isLoading: boolean) => void;
}

const ProtectedRoute = ({
    children,
    isAuthenticated,
    setIsAuthenticated,
    isLoading,
    setIsAdmin,
    setUsername,
    setUserSettings,
    setIsLoading,
}: ProtectedRouteProps) => {
    const { problem_id } = useParams();
    const navigate = useNavigate();
    const problemContext = useContext(ProblemContext);

    useEffect(() => {
        const verifyAuthentication = async () => {
            const authenticationData = await checkIfAuthenticated();
            setIsAuthenticated(authenticationData.is_authenticated);
            setIsAdmin(authenticationData.is_admin);
            setUsername(authenticationData.username);
            setUserSettings(authenticationData.userSettings);
            setIsLoading(false);
        };
        verifyAuthentication();
    }, [navigate]);

    useEffect(() => {
        if (!isLoading) {
            if (!isAuthenticated) {
                navigate("/login");
            }
        }
    }, [isLoading]);

    useEffect(() => {
        if (problem_id && problemContext) {
            const { setProblem_id } = problemContext;
            setProblem_id(Number(problem_id));
        }
    }, [problem_id]);

    if (isLoading) {
        return <PageLoadingMessage />;
    }

    return isAuthenticated ? children : <Navigate to={"/login"} />;
};

export default ProtectedRoute;
