import { ClassicPreset } from "rete";

export class CodeViewerControl extends ClassicPreset.Control {
    constructor(public currentCode: string) {
        super();
    }

    setValue(code: string) {
        this.currentCode = code;
    }
}
