import { Socket } from "rete/_types/presets/classic";
import { Pyodide } from "../../helpers/pyodideHelpers";
import { DatasetImporterTextControls } from "../ReteControls/DatasetImporterTextControl";
import { ClassicPreset } from "rete";
import { DataOutput, PreviousNodeData } from "../ReteInterfaces/SharedInterfaces";
import { delayFunction, getPreviousNodes } from "../../helpers/nodeHelpers";
import { ProblemContextType } from "../../contexts/ProblemContext";
import { RcFile } from "antd/es/upload";

interface UploadedDataset {
    content: string;
    fileName: string;
}

export class DatasetImporter extends ClassicPreset.Node<
    { input: ClassicPreset.Socket },
    { output: ClassicPreset.Socket },
    { dataset_url: DatasetImporterTextControls }
> {
    width = 400;
    height = 295;
    value: string =
        "https://raw.githubusercontent.com/datu-ca/dataset/main/classification/IRIS/data.csv";
    uploadedDataset: UploadedDataset = {
        content: "",
        fileName: "",
    };
    useUploadedDataset = true;
    datasetColumns: string[] = [];
    task_id = "createDatasetImporter";

    public onParseCsvCompleteResolve!: () => void;
    public onParseCsvCompletePromise: Promise<void>;

    private change: () => void;
    private update: (
        type: "node" | "socket",
        node: DatasetImporter | ClassicPreset.Input<ClassicPreset.Socket>
    ) => void;
    constructor(
        socket: ClassicPreset.Socket,
        update: (
            type: "node" | "socket",
            asset: DatasetImporter | ClassicPreset.Input<ClassicPreset.Socket>
        ) => void,
        change: () => void,
        context: ProblemContextType
    ) {
        super("Import Dataset");
        this.addInput("input", new ClassicPreset.Input(socket, "Input"));
        this.addOutput("output", new ClassicPreset.Output(socket, "Dataset"));
        this.addControl(
            "dataset_url",
            new DatasetImporterTextControls(
                this.value,
                this.useUploadedDataset,
                this.handleChange.bind(this),
                this.isValidCsvUrl.bind(this),
                this.setDatasetColumns.bind(this),
                this.handleUploadDataset.bind(this),
                this.handleSetDatasetSource.bind(this),
                context,
                this.signalParseCsvComplete.bind(this)
            )
        );
        this.update = update;
        this.change = change;
        this.onParseCsvCompletePromise = new Promise((resolve) => {
            this.onParseCsvCompleteResolve = resolve;
        });
    }
    getType() {
        return "DatasetImporter";
    }
    getDisplayName() {
        return "Dataset Importer";
    }
    signalParseCsvComplete() {
        this.onParseCsvCompleteResolve();
    }

    initializeParseCsvCompletionPromise() {
        this.onParseCsvCompletePromise = new Promise((resolve) => {
            this.onParseCsvCompleteResolve = resolve;
        });
    }

    async handleChange(value: string) {
        this.value = value;
        this.change();
    }

    handleUploadDataset(dataset: string, file: File | null) {
        if (dataset === "") {
            this.height = 295;
        } else if (this.uploadedDataset.content === "") {
            this.height = 325;
        }
        this.uploadedDataset.content = dataset;
        this.uploadedDataset.fileName = file?.name || "";
        setTimeout(() => {
            this.update("node", this);
        }, 100);
    }

    setDatasetColumns(datasetColumns: string[]) {
        this.datasetColumns = [...datasetColumns];
        this.update("node", this);
        this.change();
    }

    #uploadDataset(dataset: string) {
        Pyodide.saveUploadedDataset(dataset);
    }

    handleSetDatasetSource(useUploadedDataset: boolean) {
        this.useUploadedDataset = useUploadedDataset;
        this.controls.dataset_url.setUseUploadedDataset(useUploadedDataset);
        this.change();
    }

    #setDatasetSource(useUploadedDataset: boolean) {
        Pyodide.setDatasetSource(useUploadedDataset);
    }

    isValidCsvUrl(url: string) {
        const urlPattern = new RegExp(
            "^(https?:\\/\\/)?" +
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
                "((\\d{1,3}\\.){3}\\d{1,3}))" +
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
                "(\\?[;&a-z\\d%_.~+=-]*)?" +
                "(\\#[-a-z\\d_]*)?$",
            "i"
        );

        if (!urlPattern.test(url)) {
            return false;
        }
        if (!url.toLowerCase().endsWith(".csv")) {
            return false;
        }
        return true;
    }

    async data(inputs: { input?: DataOutput[] }): Promise<{ output: DataOutput }> {
        let code: string[] = [];
        const inputConnection = inputs.input?.[0];
        if (inputConnection) {
            code = [...inputConnection.code];
        }
        this.#uploadDataset(this.uploadedDataset.content);
        this.#setDatasetSource(this.useUploadedDataset);
        const previousNodes: PreviousNodeData[] = getPreviousNodes(
            inputs.input ? inputs.input : []
        );
        const currentNode = { ...this, connectedPort: this.outputs.output! };
        const output = {
            code,
            previousNodes: [...previousNodes, currentNode],
        };

        return {
            output,
        };
    }
}
