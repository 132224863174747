import { Input, Button, Upload, Switch, Flex, ConfigProvider, theme } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { DatasetImporterTextControls } from "../../ReteControls/DatasetImporterTextControl";
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import DatasetViewerComponent from "./DatasetViewerComponent";
import "../../../styles/DatasetImporter.css";
// import { delayFunction } from "../../../helpers/nodeHelpers";
import { debouncedAutoSave } from "../../editor";

const isTypeCsv = (file: UploadFile) => {
    return (
        file.type === "text/csv" ||
        file.type === "text/plain" ||
        file.type === "application/csv" ||
        file.type === "application/vnd.ms-excel" ||
        file.type === "text/x-csv"
    );
};

const delayFunction = <F extends (...args: any[]) => void>(
    fn: F,
    ms: number
): ((...args: Parameters<F>) => void) => {
    let timer: NodeJS.Timeout | null = null;

    return function (...args: Parameters<F>) {
        clearTimeout(timer as NodeJS.Timeout);
        timer = setTimeout(() => {
            fn(...(args as Parameters<F>));
        }, ms);
    };
};

const delayUrlLog = delayFunction(
    (type: string, context: any) => debouncedAutoSave(type, context),
    1000
);

const COMPONENT_ACTION_ID = "importDataset";

export const DatasetImporterComponent = (props: { data: DatasetImporterTextControls }) => {
    const [text, setText] = useState<string>(props.data.value);
    const [switchStatus, setSwitchStatus] = useState<boolean>(!props.data.useUploadedDataset);
    const [file, setFile] = useState<File | null>(null);
    const [fileUploading, setFileUploading] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const context = props.data.context;

    const toggleUrlImportStep = (action: string) => {
        if (props.data.isValidCsvUrl(action)) {
            context.markTaskAsCompleted(COMPONENT_ACTION_ID);
        } else {
            context.removeTaskFromCompleted(COMPONENT_ACTION_ID);
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.data.onChange(e.target.value);
        setText(e.target.value);
        toggleUrlImportStep(e.target.value);
        delayUrlLog("enter_download_url", {
            url_download: true,
            currentURL: e.target.value,
        });
    };

    const handleFileRead = (file: RcFile): void => {
        setFileUploading(true);
        const reader = new FileReader();
        reader.onload = async (e) => {
            const content = e.target?.result;
            if (typeof content === "string") {
                props.data.uploadDataset(content, file);
                setTimeout(() => {
                    setFile(file);
                }, 200);
            }
        };
        reader.readAsText(file);
        debouncedAutoSave("upload_local_dataset", { fileName: file.name, url_download: false });
    };

    const beforeUpload: UploadProps["beforeUpload"] = (file, fileList: UploadFile[]) => {
        if (!isTypeCsv(file)) {
            fileList[0].status = "error";
            return false;
        }

        handleFileRead(file);
        if (context) {
            const { markTaskAsCompleted } = context;
            markTaskAsCompleted(COMPONENT_ACTION_ID);
        }
        return false;
    };

    const onRemove = (file: UploadFile) => {
        props.data.uploadDataset("", null);
        setFile(null);
        props.data.setDatasetColumns([]);
        context.removeTaskFromCompleted(COMPONENT_ACTION_ID);
        debouncedAutoSave("remove_local_dataset", { fileName: file.name, url_download: false });
        return true;
    };

    const onSwitch = (checked: boolean) => {
        props.data.setDatasetSource(!checked);
        setSwitchStatus(checked);
        if (checked) {
            toggleUrlImportStep(text);
        } else if (!file) {
            context.removeTaskFromCompleted(COMPONENT_ACTION_ID);
        }
        debouncedAutoSave("toggle_url_download_switch", {
            url_download: checked,
            current_url: text,
            current_file: file?.name || "No file uploaded",
        });
    };

    useEffect(() => {
        if (containerRef.current) {
            const overalDatasetImporter: HTMLElement =
                containerRef.current.closest("[data-testid='node']") ||
                document.createElement("div");
            // Reset hardcoded dimensions from auto arrange plugin
            overalDatasetImporter.style.width = "";
            overalDatasetImporter.style.height = "";
        }
    }, [file]);

    return (
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm,
            }}
        >
            <Flex
                gap={"small"}
                align="center"
                vertical={true}
                ref={containerRef}
                onPointerDown={(e) => e.stopPropagation()}
            >
                <Upload
                    accept=".csv"
                    listType="text"
                    maxCount={1}
                    beforeUpload={beforeUpload}
                    onRemove={onRemove}
                    disabled={switchStatus}
                >
                    <Button disabled={switchStatus} icon={<UploadOutlined />}>
                        Upload dataset (csv)
                    </Button>
                </Upload>
                <span>OR</span>
                <Flex gap={"small"}>
                    <span>URL download?</span>
                    <Switch checked={switchStatus} onChange={onSwitch} disabled={fileUploading} />
                </Flex>
                <Input
                    disabled={!switchStatus}
                    placeholder="Enter dataset url"
                    value={text}
                    onChange={handleChange}
                    aria-label="Enter dataset URL"
                    name="datasetUrlInput"
                />
                <DatasetViewerComponent
                    file={file}
                    useUrl={switchStatus}
                    url={text}
                    setDatasetColumns={props.data.setDatasetColumns}
                    fileUploading={fileUploading}
                    setFileUploading={setFileUploading}
                    isValidCsvUrl={props.data.isValidCsvUrl}
                />
            </Flex>
        </ConfigProvider>
    );
};
