import React, { useEffect, useState } from "react";
import { Select, Button, List, ConfigProvider, theme, ListProps, Divider } from "antd";
import { DropdownListControl } from "../ReteControls/DropdownListControl";
import "../../styles/LibraryImportControl.css";
import { Drag } from "rete-react-plugin";
import { MinusCircleTwoTone } from "@ant-design/icons";
import { preventZoomOnScrollableElements } from "../../helpers/nodeHelpers";
import { debouncedAutoSave } from "../editor";

interface CustomDropdownListProps {
    data: DropdownListControl;
}
interface ExtendedListProps<T> extends ListProps<T> {
    onPointerDown?: (e: React.PointerEvent<HTMLDivElement>) => void;
}

const ExtendedList: React.FC<ExtendedListProps<string>> = ({ onPointerDown, ...rest }) => {
    return (
        <div onPointerDown={onPointerDown}>
            <List {...rest} />
        </div>
    );
};

const DROPDOWN_TEXT_DEFAULT = "Add Library";

function CustomDropdownList(props: CustomDropdownListProps) {
    const [selected, setSelected] = useState<string | null>(DROPDOWN_TEXT_DEFAULT);
    const [currentItems, setCurrentItems] = useState<string[]>(props.data.selectedItems);
    const context = props.data.context!;

    const handleChange = (value: string) => {
        setSelected(DROPDOWN_TEXT_DEFAULT);
        props.data.onOptionSelect(value, setCurrentItems);
        context.markTaskAsCompleted("importLibraries");
        debouncedAutoSave(`import_library`, { libraryName: value });
    };

    const handleRemove = (value: string) => {
        // If we're removing the only imported library, must revoke task completion
        if (currentItems.length === 1) {
            context.removeTaskFromCompleted("importLibraries");
        }
        props.data.removeImport(value, setCurrentItems);
        debouncedAutoSave(`remove_library_import`, { libraryName: value });
    };

    useEffect(() => {
        const listContainers = document.querySelectorAll(".listContainer");
        preventZoomOnScrollableElements(listContainers);
    }, []);

    return (
        <div className="moduleSelectorContainer">
            <ConfigProvider
                theme={{
                    algorithm: theme.darkAlgorithm,
                }}
            >
                <Drag.NoDrag>
                    <Select
                        className="selectDropDown"
                        onChange={handleChange}
                        value={selected}
                        dropdownStyle={{ width: "fit-content" }}
                        options={props.data.options.map((option, index) => {
                            let disabled = props.data.alreadySelected(option);
                            return {
                                value: option,
                                disabled: disabled,
                                title: disabled ? `${option} already imported` : `Import ${option}`,
                            };
                        })}
                    />
                    <Divider />
                    <ExtendedList
                        className="listContainer"
                        size="small"
                        bordered
                        dataSource={[...currentItems]}
                        onPointerDown={(e: React.PointerEvent<HTMLDivElement>) => {
                            e.stopPropagation();
                        }}
                        renderItem={(item: string) => (
                            <List.Item
                                className="listItem"
                                onPointerDown={(e) => {
                                    e.stopPropagation();
                                }}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                {item}
                                <MinusCircleTwoTone
                                    className="removeLibraryIcon"
                                    twoToneColor={"red"}
                                    tabIndex={0}
                                    role="button"
                                    title="Remove import"
                                    aria-label={`Remove import`}
                                    onClick={() => handleRemove(item)}
                                />
                            </List.Item>
                        )}
                    />
                </Drag.NoDrag>
            </ConfigProvider>
        </div>
    );
}

export default CustomDropdownList;
