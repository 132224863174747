import React, { Dispatch, useContext, useEffect, useRef, useState } from "react";
import "../styles/ConsoleViewer.css";
import { Spin } from "antd";
import { Pyodide, combineCodeSnippets } from "../helpers/pyodideHelpers";
import { captureEditorLog } from "./EditorButtonPanel";
import { globalEditor, globalEngine } from "./editor";
import ProblemContext from "../contexts/ProblemContext";
import { CodePath } from "./MainEditor";

interface ConsoleViewerProps {
    codePreview: string;
    runProgram: boolean;
    setRunProgram: React.Dispatch<React.SetStateAction<boolean>>;
    setGeneratedPlots: React.Dispatch<React.SetStateAction<string[]>>;
    codePaths: CodePath[];
    consoleText: string;
    setConsoleText: Dispatch<React.SetStateAction<string>>;
}

const ConsoleViewer = ({
    codePreview,
    runProgram,
    setRunProgram,
    setGeneratedPlots,
    codePaths,
    consoleText,
    setConsoleText,
}: ConsoleViewerProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const consoleRef = useRef<HTMLDivElement>(null);
    const context = useContext(ProblemContext);
    if (!context) {
        throw new Error("ProblemContext must be used within a ProblemProvider");
    }
    const { problem } = context;

    useEffect(() => {
        if (runProgram) {
            setIsLoading(true);
            const splitCode = codePreview.split("\n").filter((line) => line !== "");
            const totalCode = combineCodeSnippets(splitCode);
            if (totalCode) {
                let cumulativeConsoleOutput = "";
                let allPlots: string[] = [];
                const processCodePaths = async () => {
                    try {
                        for (const path of codePaths) {
                            const { pathNum, code } = path;
                            try {
                                if (code) {
                                    const consoleOutput = await Pyodide.runCode(code);
                                    if (consoleOutput.success) {
                                        consoleOutput.data = `[Executed successfully ✓]${
                                            consoleOutput.data ? "\n" + consoleOutput.data : ""
                                        }`;
                                    }
                                    cumulativeConsoleOutput += `Path ${pathNum} output:\n${consoleOutput.data}\n\n`;
                                    if (consoleOutput.plots) {
                                        allPlots = [...allPlots, ...consoleOutput.plots];
                                    }
                                } else {
                                    cumulativeConsoleOutput += `Path ${pathNum} output:\nError: No code to run\n\n`;
                                }
                            } catch (e: any) {
                                console.log(e);
                                cumulativeConsoleOutput += `Path ${pathNum} output:\nError: ${e.message}\n`;
                            }
                        }
                        setConsoleText(cumulativeConsoleOutput);
                        setGeneratedPlots(allPlots);
                    } catch (e: any) {
                        console.log(e);
                        setGeneratedPlots([]);
                        setConsoleText(`Error: ${e.message}`);
                    } finally {
                        setIsLoading(false);
                    }
                };
                processCodePaths();
            } else {
                setConsoleText("Error: No code to run");
                setIsLoading(false);
            }
        }
    }, [runProgram]);

    useEffect(() => {
        if (runProgram && problem && !isLoading) {
            setRunProgram(false);
        }
    }, [consoleText, isLoading]);

    useEffect(() => {
        if (consoleRef.current) {
            consoleRef.current.scrollTop = consoleRef.current.scrollHeight;
        }
    }, [consoleText]);

    return (
        <div className="rightColumnHalfContainer bottomHalf">
            <div className="consoleViewerOuterContainer">
                <h2>Console Output</h2>
                <div ref={consoleRef} className="consoleOutputContainer">
                    <Spin
                        wrapperClassName="consoleLoadingOverlay"
                        tip="Processing changes..."
                        spinning={isLoading}
                        size="large"
                    >
                        <div className="console globalConsole">
                            <pre>{consoleText}</pre>
                        </div>
                    </Spin>
                </div>
            </div>
        </div>
    );
};

export default ConsoleViewer;
