import React, { useContext, useEffect, useRef, useState } from "react";
import Prism from "prismjs";
import "prismjs/components/prism-python";
import "prismjs/themes/prism-okaidia.css";
import "../styles/ProblemSubmissionSummary.css";
import { TaskType, useSubmission } from "../providers/ProblemSubmissionProvider";
import { BACKEND_URL } from "../config";
import lib, { ArrowRightOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Button, Card, ConfigProvider, Divider, Select, theme } from "antd";
import { useNavigate } from "react-router-dom";
import ProblemContext from "../contexts/ProblemContext";
import { userSettingsType } from "./App";
import { axiosInstance, getDetailsFromAction, saveUserLog, UserLog } from "../helpers/nodeHelpers";
import EditorStateNodeCard from "./EditorStateNodeCard";

type Props = {
    userSettings: userSettingsType;
    username: string;
};

const ProblemSubmissionSummary = ({ userSettings, username }: Props) => {
    const codeRef = useRef<HTMLPreElement>(null);
    const [localDateStarted, setLocalDateStarted] = useState<string | null>(null);
    const [localDateCompleted, setLocalDateCompleted] = useState<string | null>(null);
    const [elapsedTime, setElapsedTime] = useState<string | null>(null);
    const [sortedCompleteTasks, setSortedCompleteTasks] = useState<TaskType[]>([]);
    const [localProblem_id, setLocalProblem_id] = useState<number | null>(null);
    const { submissionDetails, setSubmissionDetails } = useSubmission();
    const problemContext = useContext(ProblemContext);
    const navigate = useNavigate();

    if (!submissionDetails) {
        return <div>Loading...</div>;
    }

    const { problem, problem_submission, tasks, problem_submission_numbers, submission_images } =
        submissionDetails;

    const {
        submission_number,
        confidence,
        date_started,
        date_completed,
        full_code,
        console_output,
        image_url,
        editor_state,
    } = problem_submission;

    const convertToLocalTime = (dateString: Date) => {
        const date = new Date(dateString);
        return date.toLocaleString();
    };

    const getElapsedTime = (dateStarted: Date, dateCompleted: Date) => {
        const dateStartedObj = new Date(dateStarted);
        const dateCompletedObj = new Date(dateCompleted);
        const elapsedTime = dateCompletedObj.getTime() - dateStartedObj.getTime();

        const days = Math.floor(elapsedTime / (1000 * 60 * 60 * 24));
        const hours = Math.floor((elapsedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60));

        let result = "";
        if (days > 0) {
            result += `${days} days, `;
        }
        if (hours > 0 || days > 0) {
            result += `${hours} hours, `;
        }
        result += `${minutes} minutes`;
        return result.trim();
    };

    const logSubmissionEvent = (action: string, eventData: any) => {
        const logData: UserLog = {
            action: action,
            problem_id: localProblem_id!,
            eventData,
            details: getDetailsFromAction(action, eventData),
        };
        saveUserLog(logData);
    };

    const handleNewSubmission = async () => {
        try {
            const res = await axiosInstance.post(`/api/problems/mark-in-progress`, {
                problem_id: localProblem_id,
            });
            if (problemContext) {
                const { setProblem } = problemContext;
                setProblem((prev) => {
                    if (prev) {
                        return { ...prev, problem_status: "in-progress" };
                    }
                    return prev;
                });
            }
            if (problemContext) {
                const { problem } = problemContext;
                const eventData = {
                    problem_number: problem?.problem_number,
                    submission_number: res.data.submission_number + 1,
                };
                logSubmissionEvent("start_new_submission", eventData);
            }
            navigate(`/problems/${localProblem_id}`);
        } catch (e) {
            console.log(e);
        }
    };

    const handleSubmissionChange = async (submissionNumber: string) => {
        if (submissionNumber === submission_number.toString()) return;
        try {
            const res = await axiosInstance.post("/api/problems/get-problem-submission", {
                submission_number: submissionNumber,
                problem_id: localProblem_id,
            });
            const selectedSubmission = res.data.problem_submission;
            const selectedTasks = res.data.tasks;

            const eventData = {
                problem_number: problem.problem_number,
                submission_number: submissionNumber,
            };
            let action = "view_past_submission";
            if (selectedSubmission.submission_number === Math.max(...problem_submission_numbers)) {
                action = "view_latest_submission";
            }
            logSubmissionEvent(action, eventData);

            setSubmissionDetails((prev) => {
                if (prev) {
                    return {
                        ...prev,
                        problem_submission: selectedSubmission,
                        submission_images: selectedSubmission.submission_images,
                        tasks: selectedTasks,
                    };
                }
                return prev;
            });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        const dateStartedString = convertToLocalTime(date_started);
        const dateCompletedString = convertToLocalTime(date_completed);
        const elapsedTimeString = getElapsedTime(date_started, date_completed);
        setLocalDateStarted(dateStartedString);
        setLocalDateCompleted(dateCompletedString);
        setElapsedTime(elapsedTimeString);
    }, [date_started, date_completed]);

    useEffect(() => {
        if (full_code && codeRef.current) {
            Prism.highlightElement(codeRef.current);
        }
    }, [full_code]);

    useEffect(() => {
        const tempSortedTasks = [...tasks].sort((a, b) => a.task_number - b.task_number);
        setSortedCompleteTasks(tempSortedTasks.filter((task) => task.is_completed));
    }, [tasks]);

    useEffect(() => {
        if (problemContext) {
            const { problem } = problemContext;
            if (problem) {
                setLocalProblem_id(problem.id);
            }
        }
    }, [problemContext]);

    useEffect(() => {
        if (localProblem_id) {
            setTimeout(() => {
                logSubmissionEvent("view_latest_submission", {
                    problem_number: problem.problem_number,
                    submission_number: submission_number,
                });
            }, 200);
        }
    }, [localProblem_id]);

    return (
        <div className="problemSubmissionMainContainer">
            <div className="referenceContainerForButtons">
                <ConfigProvider
                    theme={{
                        algorithm: theme.darkAlgorithm,
                    }}
                >
                    <div className="problemSubmissionButtonPanel">
                        <Button onClick={() => navigate("/problems")}>Go to Problems</Button>
                        <Button
                            onClick={() => navigate(`/problems/${localProblem_id}/description`)}
                        >
                            Problem Description
                        </Button>
                        <Button onClick={() => navigate(`/logout`)}>Logout</Button>
                    </div>
                </ConfigProvider>
                <div className="problemSubmissionInnerContainer">
                    <div className="problemSubmissionSection">
                        <div className="titleContainer">
                            <h1>Submission For: {username}</h1>
                            <h2>{`Problem ${problem.problem_number} - ${problem.title}`}</h2>
                            <ConfigProvider
                                theme={{
                                    algorithm: theme.darkAlgorithm,
                                }}
                            >
                                <div className="reSubmitProblemContainer">
                                    <Button
                                        className="reSubmitProblemButton"
                                        onClick={handleNewSubmission}
                                    >
                                        New Submission
                                    </Button>
                                    <p>(Start from the most recent submission state)</p>
                                </div>
                                <div className="submissionSelectionContainer">
                                    <h3>Currently Viewing</h3>
                                    <Select
                                        defaultValue={submission_number.toString()}
                                        onSelect={handleSubmissionChange}
                                        className="submissionSelect"
                                        dropdownStyle={{ width: "135px" }}
                                    >
                                        {problem_submission_numbers.map((submissionNumber) => {
                                            return (
                                                <Select.Option
                                                    key={submissionNumber}
                                                    value={submissionNumber.toString()}
                                                >
                                                    {`Submission ${submissionNumber}`}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                    {submission_number ===
                                    Math.max(...problem_submission_numbers) ? (
                                        <h4>(Latest submission)</h4>
                                    ) : (
                                        <h4>(Previous submission)</h4>
                                    )}
                                </div>
                            </ConfigProvider>
                            <p>{`Date started: ${localDateStarted}`}</p>
                            <p>{`Date submitted: ${localDateCompleted}`}</p>
                            <p>{`Time elapsed: ${elapsedTime}`}</p>
                            <p>{`Confidence: ${confidence}%`}</p>
                        </div>
                    </div>

                    {tasks.length > 0 && (!userSettings || userSettings.taskbar_enabled) && (
                        <div className="problemSubmissionSection">
                            <h2>{`Tasks (${`${
                                sortedCompleteTasks.filter((task) => task.is_completed).length
                            }/${tasks.length}`})`}</h2>
                            <Divider className="problemSubmissionDivider" />
                            <div
                                className="problemTasksContainer"
                                style={{
                                    justifyContent: `${
                                        sortedCompleteTasks.length !== tasks.length
                                            ? "space-evenly"
                                            : "flex-start"
                                    }`,
                                }}
                            >
                                <div className="completeTasksContainer">
                                    <h3>{`Completed `}</h3>
                                    <ul>
                                        {sortedCompleteTasks.map((task) => {
                                            return (
                                                <li className="completedTaskItem" key={task.id}>
                                                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                                                    <span>{task.title}</span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                                {sortedCompleteTasks.length !== tasks.length && (
                                    <>
                                        <Divider type="vertical" style={{ height: "auto" }} />
                                        <div className="incompleteTasksContainer">
                                            <h3>Incomplete</h3>
                                            <ul>
                                                {tasks
                                                    .filter((task) => !task.is_completed)
                                                    .map((task) => {
                                                        return (
                                                            <li
                                                                key={task.id}
                                                                className="completedTaskItem"
                                                            >
                                                                <CloseCircleTwoTone twoToneColor="#ff0000" />
                                                                <span>{task.title}</span>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="problemSubmissionSection">
                        <h2>Snapshot of your submission</h2>
                        <Divider className="problemSubmissionDivider" />
                        <div className="editorScreenshotContainer">
                            <img src={`${BACKEND_URL}${image_url}`} alt="Editor Screenshot" />
                        </div>
                        <h2>Blocks Used</h2>
                        <Divider className="problemSubmissionDivider" />
                        <ul className="usedNodes">
                            {editor_state.nodes.map((node) => {
                                return (
                                    <EditorStateNodeCard
                                        key={node.id}
                                        editorState={editor_state}
                                        node={node}
                                    />
                                );
                            })}
                        </ul>
                    </div>
                    <div className="problemSubmissionSection">
                        <h2>Generated Code</h2>
                        <Divider className="problemSubmissionDivider" />
                        <ul className="usedPaths">
                            {editor_state.paths.map((path) => {
                                return (
                                    <li key={path.pathNumber}>
                                        <h3>{`Path ${path.pathNumber} Blocks`}</h3>
                                        <p>
                                            {`${path.startNode}`} <ArrowRightOutlined />
                                            {` ${path.endNode}`}
                                        </p>
                                    </li>
                                );
                            })}
                        </ul>
                        <pre className="language-python problemSubmissionCode">
                            <code className="language-python" ref={codeRef}>
                                {full_code}
                            </code>
                        </pre>
                    </div>
                    <div className="problemSubmissionSection">
                        <h2>Console Output</h2>
                        <Divider className="problemSubmissionDivider" />
                        <div className="console">
                            <pre>{console_output || "No output generated"}</pre>
                        </div>
                    </div>

                    {submission_images.length > 0 && (
                        <div className="problemSubmissionSection">
                            <h2>Generated Images</h2>
                            <Divider />
                            {submission_images.map((image) => (
                                <div className="" key={image.id}>
                                    <h4>{`${image.image_title}`}</h4>
                                    <div className="editorScreenshotContainer">
                                        <img
                                            src={`${BACKEND_URL}${image.image_path}`}
                                            alt={image.image_title}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProblemSubmissionSummary;
