import { Button, ConfigProvider, Tabs, Tooltip, theme } from "antd";
import { Layout } from "antd";
const { Sider } = Layout;
import React, {
    Children,
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import DockMenu from "./DockMenu";
import Roadmap from "./Roadmap";
import {
    DoubleLeftOutlined,
    DoubleRightOutlined,
    VerticalLeftOutlined,
    VerticalRightOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../helpers/nodeHelpers";
import ProblemContext from "../contexts/ProblemContext";
import { handleZoomToFit } from "./EditorButtonPanel";
import { userSettingsType } from "./App";
import Notes from "./Notes";

interface LeftSideBarProps {
    leftRef: React.RefObject<HTMLDivElement>;
    setIsAuthenticated: (isAuthenticated: boolean) => void;
    userSettings: userSettingsType;
    username: string;
}

const LeftSideBar = (props: LeftSideBarProps) => {
    const navigate = useNavigate();
    const { setIsAuthenticated, leftRef, userSettings } = props;
    const [collapsed, setCollapsed] = useState(false);
    const [activeKey, setActiveKey] = useState("1");
    const [tooltipOpen, setTooltipOpen] = useState<boolean | undefined>();
    const [isOverflowing, setIsOverflowing] = useState(false);
    const usernameRef = useRef<HTMLSpanElement>(null);
    const problemContext = useContext(ProblemContext);
    if (!problemContext) throw new Error("ProblemContext not found");
    const { setProblem_id, tasks } = problemContext;

    const tabItems = [
        ...((!userSettings || userSettings?.taskbar_enabled) && tasks.length > 0
            ? [
                  {
                      key: "1",
                      label: "Tasks",
                      children: (
                          <Roadmap setIsAuthenticated={setIsAuthenticated} collapsed={collapsed} />
                      ),
                  },
              ]
            : []),
        {
            key: (!userSettings || userSettings?.taskbar_enabled) && tasks.length > 0 ? "2" : "1",
            label: "Blocks",
            children: <DockMenu collapsed={collapsed} />,
        },
        {
            key: (!userSettings || userSettings?.taskbar_enabled) && tasks.length > 0 ? "3" : "2",
            label: "Notes",
            children: <Notes collapsed={collapsed} setIsOverflowing={setIsOverflowing} />,
        },
    ];

    const handleTabClick = (key: string) => {
        if (collapsed) {
            setCollapsed(false);
        }
        setActiveKey(key);
    };

    const handleLogout = async () => {
        try {
            const response = await axiosInstance.post(
                "/api/users/logout",
                {},
                { withCredentials: true }
            );
            setIsAuthenticated(false);
            setProblem_id(undefined);
            navigate("/login");
        } catch (error) {
            console.log("Logout error ->", error);
        }
    };

    const resetClosedTooltip = () => {
        setTooltipOpen(false);
        setTimeout(() => {
            setTooltipOpen(undefined);
        }, 10);
    };

    const handleCollapse = () => {
        setCollapsed((prev) => !prev);
        resetClosedTooltip();
        setTimeout(() => {
            handleZoomToFit();
        }, 0);
    };

    const adjustUsernameFontSize = () => {
        const container = usernameRef.current;
        if (!container) return;

        let fontSize = 20; // px
        container.style.fontSize = fontSize + "px";
        container.style.textWrap = "nowrap";
        container.style.overflowWrap = "normal";

        while (container.scrollWidth > container.offsetWidth) {
            fontSize--;
            container.style.fontSize = fontSize + "px";
            if (fontSize <= 10) {
                if (container.scrollWidth > container.offsetWidth) {
                    container.style.textWrap = "balance";
                    container.style.overflowWrap = "break-word";
                }
                break;
            }
        }
    };

    useEffect(() => {
        adjustUsernameFontSize();
    }, [props.username]);

    return (
        <Layout ref={leftRef} style={{ flex: "0 0 110px" }}>
            <div
                id="welcomeUsernameContainer"
                className={`welcomeUsernameMsg${collapsed ? " adjustUserWelcomeMsg" : ""}`}
            >
                <p>Welcome User</p>
                <span
                    id="usernameText"
                    ref={usernameRef}
                    className="usernameBold"
                    title={props.username}
                >
                    {props.username}
                </span>
            </div>

            <Sider
                className={`siderContainer ${collapsed ? "siderCollapsed" : ""}`}
                collapsible
                collapsed={collapsed}
                trigger={null}
            >
                <Tooltip
                    placement="right"
                    title={collapsed ? "Expand Sidebar" : "Collapse Sidebar"}
                    open={tooltipOpen}
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0.5}
                >
                    <Button
                        className={`siderCollapseBtn topSiderButton ${
                            collapsed ? "siderCollapseBtnCollapsed" : ""
                        }`}
                        onClick={handleCollapse}
                        icon={collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
                    ></Button>
                </Tooltip>
                <Tabs
                    className={`siderTabs ${collapsed ? "siderTabsCollapsed" : ""}`}
                    tabPosition="left"
                    defaultActiveKey="1"
                    onTabClick={handleTabClick}
                    items={tabItems}
                    activeKey={collapsed ? "" : activeKey}
                />
                <Tooltip
                    placement="right"
                    title={collapsed ? "Expand Sidebar" : "Collapse Sidebar"}
                    open={tooltipOpen}
                    mouseEnterDelay={0}
                    mouseLeaveDelay={0.5}
                >
                    <Button
                        className={`siderCollapseBtn bottomSiderButton ${
                            collapsed ? "siderCollapseBtnCollapsed" : ""
                        } ${isOverflowing ? "ajustForOverflow" : ""}`}
                        onClick={handleCollapse}
                        icon={collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
                    ></Button>
                </Tooltip>
                <Button className="siderProblemsBtn" onClick={() => navigate("/problems")}>
                    All Problems
                </Button>
                <ConfigProvider theme={{ algorithm: theme.darkAlgorithm }}>
                    <Button className="siderLogoutBtn" onClick={handleLogout}>
                        Logout
                    </Button>
                </ConfigProvider>
            </Sider>
        </Layout>
    );
};

export default LeftSideBar;
