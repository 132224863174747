import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "../styles/DockMenu.css";
import { ConfigProvider, Divider, Popover, theme } from "antd";
import { BACKEND_URL } from "../config";

interface NodeItem {
    displayName: string;
    blockType: string;
    preview_image_name: string;
    description: string;
    inputs: {
        displayName: string;
        description: string;
    }[];
    outputs: {
        displayName: string;
        description: string;
    }[];
}

interface SubCategory {
    name: string;
    items: NodeItem[];
}

interface NodeCategory {
    name: string;
    hasSubCategories: boolean;
    items?: NodeItem[];
    subCategories?: SubCategory[];
}

const nodeCategories: NodeCategory[] = [
    {
        name: "Import",
        hasSubCategories: false,
        items: [
            {
                displayName: "Dataset Importer",
                blockType: "DatasetImporter",
                preview_image_name: "DatasetImporter_small",
                description:
                    "Import and preview a dataset from a local file or via URL download. The dataset is made available for the rest of the blocks in the pipeline.",
                inputs: [],
                outputs: [
                    {
                        displayName: "Dataset",
                        description: "Sets the dataset for the rest of the blocks in the pipeline.",
                    },
                ],
            },
            {
                displayName: "Library Importer",
                blockType: "PackageImporter",
                preview_image_name: "PackageImporter_small",
                description:
                    "Import Python libraries for the block pipeline. The libraries are made available for the rest of the blocks in the pipeline.",
                inputs: [],
                outputs: [
                    {
                        displayName: "Imported Libraries",
                        description: "Injects the imported libraries into the pipeline.",
                    },
                ],
            },
        ],
    },
    {
        name: "Dataframe Tools",
        hasSubCategories: false,
        items: [
            {
                displayName: "Dataframe Loader",
                blockType: "DataframeLoader",
                preview_image_name: "DataframeLoader_small",
                description: `Load a Pandas dataframe by connecting the output of a dataset and library importer to the inputs of the dataframe loader. The dataframe (df) is available for the rest of the blocks in the pipeline.
<h4 style="margin-top:0.5rem; font-weight:600; text-decoration:underline;">Prerequisites:</h4>
<ul class="blockPreRequisites">
    <li>A dataset must be loaded (connect to dataset importer block)</li>
    <li><span style="font-weight:600;">Pandas</span> must be imported</li>
</ul>`,
                inputs: [
                    {
                        displayName: "Imported Dataset",
                        description: "Connect to a dataset importer block to load the dataset.",
                    },
                    {
                        displayName: "Imported Packages",
                        description: "Connect to a library importer block to load the libraries.",
                    },
                ],
                outputs: [
                    {
                        displayName: "Export Dataframe",
                        description:
                            "Dataframe is available for rest of pipeline as variable 'df'.",
                    },
                ],
            },
            {
                displayName: "Dataframe Inspector",
                blockType: "DataframeInspector",
                preview_image_name: "DataframeInspector_small",
                description: `Inspect a dataframe (df) by choosing one of the following operations:
<ul>
    <li><span class="boldText">Info</span>: provides a concise summary of the dataframe</li>
    <li><span class="boldText">Head</span>: displays the first few rows</li>
    <li><span class="boldText">Tail</span>: displays the last few rows</li>
    <li><span class="boldText">Describe</span>: provides descriptive statistics</li>
    <li><span class="boldText">Columns</span>: lists all column names</li>
    <li><span class="boldText">Shape</span>: gives the number of rows and columns</li>
</ul>
The output of the chosen operation is displayed in the console output.
<h4 style="margin-top:0.5rem; font-weight:600; text-decoration:underline;">Prerequisites:</h4>
<ul class="blockPreRequisites">
    <li>Connect to a series of blocks which have loaded a dataframe.</li>
</ul>`,
                inputs: [
                    {
                        displayName: "Dataframe",
                        description: "Connect to a series of blocks which have loaded a dataframe.",
                    },
                ],
                outputs: [
                    {
                        displayName: "Dataframe",
                        description:
                            "Adds a print statement with the selected dataframe operation to the code. Viewable output found in console viewer or global console output after code has been run.",
                    },
                ],
            },
            {
                displayName: "Test-Train-Split",
                blockType: "TestTrainSplit",
                preview_image_name: "TestTrainSplit_small",
                description: `Split a dataframe (df) into training and testing data.
<h4 style="margin-top:0.5rem; font-weight:600; text-decoration:underline;">Prerequisites:</h4>
<ul class="blockPreRequisites">
    <li>Connect to a series of blocks which have loaded a dataframe.</li>
    <li><span style="font-weight:600;">Scikit-learn</span> must be imported</li>
</ul>`,
                inputs: [
                    {
                        displayName: "Dataframe",
                        description: "Connect to a series of blocks which have loaded a dataframe.",
                    },
                ],
                outputs: [
                    {
                        displayName: "Train Dataframe",
                        description: "Training data for the model.",
                    },
                    {
                        displayName: "Test Dataframe",
                        description: "Testing data for the model.",
                    },
                ],
            },
        ],
    },
    {
        name: "Model",
        hasSubCategories: true,
        subCategories: [
            {
                name: "Model Selection",
                items: [
                    {
                        displayName: "Linear Regression Model",
                        blockType: "LinearRegressionModel",
                        preview_image_name: "LinearRegressionModel_small",
                        description: "Setup an untrained linear regression model.",
                        inputs: [],
                        outputs: [
                            {
                                displayName: "Untrained Model",
                                description: "Untrained linear regression model.",
                            },
                        ],
                    },
                    {
                        displayName: "Logistic Regression Model",
                        blockType: "LogisticRegressionModel",
                        preview_image_name: "LogisticRegressionModel_small",
                        description: "Setup an untrained logistic regression model.",
                        inputs: [],
                        outputs: [
                            {
                                displayName: "Untrained Model",
                                description: "Untrained logistic regression model.",
                            },
                        ],
                    },
                ],
            },
            {
                name: "Model Training",
                items: [
                    {
                        displayName: "Train Model",
                        blockType: "TrainModel",
                        preview_image_name: "TrainModel_small",
                        description: "Train an untrained model with a training dataframe.",
                        inputs: [
                            {
                                displayName: "Untrained Model",
                                description: "Connect to a model block.",
                            },
                            {
                                displayName: "Train Dataframe",
                                description:
                                    "Connect to the Train Dataframe output of a Test-Train-Split block.",
                            },
                        ],
                        outputs: [
                            {
                                displayName: "Trained Model",
                                description: "Trained model ready for use in prediction.",
                            },
                        ],
                    },
                ],
            },
            {
                name: "Model Prediction",
                items: [
                    {
                        displayName: "Predict Model",
                        blockType: "PredictModel",
                        preview_image_name: "PredictModel_small",
                        description: "Predict the test data using a trained model.",
                        inputs: [
                            {
                                displayName: "Trained Model",
                                description: "Connect to a model block.",
                            },
                            {
                                displayName: "Test Dataframe",
                                description:
                                    "Connect to the Test Dataframe output of a Test-Train-Split block.",
                            },
                        ],
                        outputs: [
                            {
                                displayName: "Predictions",
                                description:
                                    "Predictions made by the model based on the test data.",
                            },
                        ],
                    },
                ],
            },
            {
                name: "Model Evaluation",
                items: [
                    {
                        displayName: "Score Model Basic",
                        blockType: "ScoreModelBasic",
                        preview_image_name: "ScoreModelBasic_small",
                        description:
                            "Scores the model using a basic scoring method, score type depends on model used.",
                        inputs: [
                            {
                                displayName: "Predictions",
                                description:
                                    "Connect to the Predictions output of a Predict Model block.",
                            },
                            {
                                displayName: "Test Dataframe",
                                description:
                                    "Connect to the Test Dataframe output of a Test-Train-Split block.",
                            },
                        ],
                        outputs: [
                            {
                                displayName: "Score",
                                description:
                                    "Basic scoring of the model, score type depends on model used.",
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        name: "Display",
        hasSubCategories: false,
        items: [
            // {
            //     displayName: "Code Viewer",
            //     blockType: "CodeViewer",
            //     preview_image_name: "CodeViewer_small",
            //     description:
            //         "Displays the code equivalent of the connected blocks. Even though the global code preview exists, this block is useful for viewing code up to a specific point in the pipeline.",
            //     inputs: [
            //         {
            //             displayName: "Code input",
            //             description:
            //                 "Connect to any series of blocks to view the equivalent Python code.",
            //         },
            //     ],
            //     outputs: [],
            // },
            // {
            //     displayName: "Console Viewer",
            //     blockType: "ConsoleViewer",
            //     preview_image_name: "ConsoleViewer_small",
            //     description:
            //         "Displays the console output as a result of running the equivalent code of the connected blocks.",
            //     inputs: [
            //         {
            //             displayName: "Code input",
            //             description: "Connect to a series of blocks which produce code output.",
            //         },
            //     ],
            //     outputs: [],
            // },
            {
                displayName: "Visualizer",
                blockType: "Visualizer",
                preview_image_name: "Visualizer_small",
                description: `Visualize a connected dataframe (df) through a series of different plot types. Generate and save plots after running the block program.
<h4 style="margin-top:0.5rem; font-weight:600; text-decoration:underline;">Prerequisites:</h4>
<ul class="blockPreRequisites">
    <li>Connect to a series of blocks which have loaded a dataframe.</li>
    <li><span style="font-weight:600;">Matplotlib</span> must be imported</li>
    <li><span style="font-weight:600;">Seaborn</span> must be imported for <span style="font-weight:600;">Heatmaps</span> and <span style="font-weight:600;">Scatter plots</span></li>

</ul>`,
                inputs: [
                    {
                        displayName: "Data",
                        description: "Connect to a series of blocks which have loaded a dataframe.",
                    },
                ],
                outputs: [],
            },
        ],
    },
];

interface DockMenuProps {
    collapsed: boolean;
}

const DockMenu = ({ collapsed }: DockMenuProps) => {
    const [delayRender, setDelayRender] = useState(false);
    const [forceClosePopovers, setForceClosePopovers] = useState<boolean | undefined>(undefined);

    const onDragStart = async (e: React.DragEvent<HTMLLIElement>) => {
        // Force close all popovers once user starts dragging
        setForceClosePopovers(false);
        setTimeout(() => {
            // Reset popovers back to automatic open/close on hover
            setForceClosePopovers(undefined);
        }, 10);
        const blockType = e.currentTarget.getAttribute("data-block-type") || "";
        e.dataTransfer.setData("blockType", blockType);

        const newImg = new Image();
        const previewImageName = e.currentTarget.getAttribute("data-preview-image");

        newImg.src = `${BACKEND_URL}/block-previews/${previewImageName}.png`;
        e.dataTransfer.setDragImage(newImg, 0, 0);
    };

    useEffect(() => {
        if (!collapsed) {
            const timer = setTimeout(() => {
                setDelayRender(collapsed);
            }, 300);
            return () => {
                clearTimeout(timer);
            };
        } else {
            setDelayRender(collapsed);
        }
    }, [collapsed]);

    return (
        <div className={`dockMenuOverallContainer ${collapsed ? "sidebarCollapsed" : ""}`}>
            {!delayRender && (
                <>
                    <h1>Blocks</h1>
                    <ul className="dockMenuElementList">
                        {nodeCategories.map((category, index) => (
                            <li key={category.name}>
                                <h3>{category.name}</h3>
                                {category.hasSubCategories ? (
                                    <ul className="subCategoryList">
                                        {category.subCategories?.map((subCategory, subIndex) => (
                                            <li className="subCategory" key={subIndex}>
                                                <h4>{subCategory.name}</h4>
                                                <ul>
                                                    {subCategory.items.map((node, itemIndex) => (
                                                        <ConfigProvider
                                                            theme={{
                                                                algorithm: theme.darkAlgorithm,
                                                            }}
                                                            key={node.blockType}
                                                        >
                                                            <Popover
                                                                open={forceClosePopovers}
                                                                placement="rightTop"
                                                                title={
                                                                    <h3 className="nodePreviewTitle">
                                                                        {node.displayName}
                                                                    </h3>
                                                                }
                                                                mouseEnterDelay={0.2}
                                                                mouseLeaveDelay={0}
                                                                overlayClassName="blockPopoverOverlay"
                                                                content={
                                                                    <div className="blockPopoverContent">
                                                                        <div className="blockDescriptionContainer">
                                                                            <div
                                                                                className="blockDescriptionText"
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: node.description,
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <Divider className="divider" />
                                                                        <div className="inputOutputContainer">
                                                                            <div
                                                                                className={`inputsColumn ${
                                                                                    node.inputs
                                                                                        .length == 0
                                                                                        ? "emptyInputOutput"
                                                                                        : ""
                                                                                }`}
                                                                            >
                                                                                <h3 className="inputOutputTitle">
                                                                                    Inputs
                                                                                </h3>
                                                                                {node.inputs
                                                                                    .length > 0 ? (
                                                                                    <ul>
                                                                                        {node.inputs.map(
                                                                                            (
                                                                                                input,
                                                                                                inputIndex
                                                                                            ) => (
                                                                                                <li
                                                                                                    key={
                                                                                                        inputIndex
                                                                                                    }
                                                                                                >
                                                                                                    <span className="boldText">
                                                                                                        {`${input.displayName}`}
                                                                                                    </span>
                                                                                                    <span>
                                                                                                        {` - ${input.description}`}
                                                                                                    </span>
                                                                                                </li>
                                                                                            )
                                                                                        )}
                                                                                    </ul>
                                                                                ) : (
                                                                                    <p>None</p>
                                                                                )}
                                                                            </div>
                                                                            <div className="dividerContainer">
                                                                                <Divider
                                                                                    className="verticalDivider"
                                                                                    type="vertical"
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                className={`outputsColumn ${
                                                                                    node.outputs
                                                                                        .length == 0
                                                                                        ? "emptyInputOutput"
                                                                                        : ""
                                                                                }`}
                                                                            >
                                                                                <h3 className="inputOutputTitle">
                                                                                    Outputs
                                                                                </h3>
                                                                                {node.outputs &&
                                                                                node.outputs
                                                                                    .length > 0 ? (
                                                                                    <ul>
                                                                                        {node.outputs.map(
                                                                                            (
                                                                                                output,
                                                                                                outputIndex
                                                                                            ) => (
                                                                                                <li
                                                                                                    key={
                                                                                                        outputIndex
                                                                                                    }
                                                                                                >
                                                                                                    <p>
                                                                                                        <span className="boldText">
                                                                                                            {`${output.displayName}`}
                                                                                                        </span>
                                                                                                        <span>
                                                                                                            {` - ${output.description}`}
                                                                                                        </span>
                                                                                                    </p>
                                                                                                </li>
                                                                                            )
                                                                                        )}
                                                                                    </ul>
                                                                                ) : (
                                                                                    <p>None</p>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            >
                                                                <li
                                                                    className="dockMenuElement"
                                                                    data-block-type={node.blockType}
                                                                    data-preview-image={
                                                                        node.preview_image_name
                                                                    }
                                                                    draggable
                                                                    onDragStart={onDragStart}
                                                                >
                                                                    {node.displayName}
                                                                    {node.preview_image_name && (
                                                                        <img
                                                                            className="offscreen"
                                                                            src={`${BACKEND_URL}/block-previews/${node.preview_image_name}.png`}
                                                                            alt={`Preview image for ${node.displayName}`}
                                                                        />
                                                                    )}
                                                                </li>
                                                            </Popover>
                                                        </ConfigProvider>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <ul>
                                        {category.items?.map((node, itemIndex) => (
                                            <ConfigProvider
                                                theme={{
                                                    algorithm: theme.darkAlgorithm,
                                                }}
                                                key={node.blockType}
                                            >
                                                <Popover
                                                    open={forceClosePopovers}
                                                    placement="rightTop"
                                                    title={
                                                        <h2 className="nodePreviewTitle">
                                                            {node.displayName}
                                                        </h2>
                                                    }
                                                    overlayClassName="blockPopoverOverlay"
                                                    mouseEnterDelay={0.2}
                                                    mouseLeaveDelay={0}
                                                    content={
                                                        <div className="blockPopoverContent">
                                                            <div className="blockDescriptionContainer">
                                                                <div
                                                                    className="blockDescriptionText"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: node.description,
                                                                    }}
                                                                />
                                                            </div>
                                                            <Divider className="divider" />
                                                            <div className="inputOutputContainer">
                                                                <div
                                                                    className={`inputsColumn ${
                                                                        node.inputs.length == 0
                                                                            ? "emptyInputOutput"
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    <h3 className="inputOutputTitle">
                                                                        Inputs
                                                                    </h3>
                                                                    {node.inputs.length > 0 ? (
                                                                        <ul>
                                                                            {node.inputs.map(
                                                                                (
                                                                                    input,
                                                                                    inputIndex
                                                                                ) => (
                                                                                    <li
                                                                                        key={
                                                                                            inputIndex
                                                                                        }
                                                                                    >
                                                                                        <span className="boldText">
                                                                                            {`${input.displayName}`}
                                                                                        </span>
                                                                                        <span>
                                                                                            {` - ${input.description}`}
                                                                                        </span>
                                                                                    </li>
                                                                                )
                                                                            )}
                                                                        </ul>
                                                                    ) : (
                                                                        <p>None</p>
                                                                    )}
                                                                </div>
                                                                <div className="dividerContainer">
                                                                    <Divider
                                                                        className="verticalDivider"
                                                                        type="vertical"
                                                                    />
                                                                </div>
                                                                <div
                                                                    className={`outputsColumn ${
                                                                        node.outputs.length == 0
                                                                            ? "emptyInputOutput"
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    <h3 className="inputOutputTitle">
                                                                        Outputs
                                                                    </h3>
                                                                    {node.outputs &&
                                                                    node.outputs.length > 0 ? (
                                                                        <ul>
                                                                            {node.outputs.map(
                                                                                (
                                                                                    output,
                                                                                    outputIndex
                                                                                ) => (
                                                                                    <li
                                                                                        key={
                                                                                            outputIndex
                                                                                        }
                                                                                    >
                                                                                        <p>
                                                                                            <span className="boldText">
                                                                                                {`${output.displayName}`}
                                                                                            </span>
                                                                                            <span>
                                                                                                {` - ${output.description}`}
                                                                                            </span>
                                                                                        </p>
                                                                                    </li>
                                                                                )
                                                                            )}
                                                                        </ul>
                                                                    ) : (
                                                                        <p>None</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                >
                                                    <li
                                                        className="dockMenuElement"
                                                        data-block-type={node.blockType}
                                                        data-preview-image={node.preview_image_name}
                                                        draggable
                                                        onDragStart={onDragStart}
                                                    >
                                                        <span>{node.displayName}</span>
                                                        {node.preview_image_name && (
                                                            <img
                                                                className="offscreen"
                                                                src={`${BACKEND_URL}/block-previews/${node.preview_image_name}.png`}
                                                                alt={`Preview image for ${node.displayName}`}
                                                            />
                                                        )}
                                                    </li>
                                                </Popover>
                                            </ConfigProvider>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
};

export default DockMenu;
