import { Space, Select, InputNumber } from "antd";
import React, { useState } from "react";
import { Drag } from "rete-react-plugin";
import "../../../styles/Models.css";
import { LogisticRegressionSettings } from "../../ReteControls/Models/LogisticRegressionSettings";
import { debouncedAutoSave } from "../../editor";

const solverOptions = [
    { value: "liblinear", label: "liblinear" },
    { value: "newton-cg", label: "newton-cg" },
    { value: "lbfgs", label: "lbfgs" },
    { value: "sag", label: "sag" },
    { value: "saga", label: "saga" },
];

const penaltyOptions = [
    { value: "l1", label: "l1" },
    { value: "l2", label: "l2" },
    { value: "elasticnet", label: "elasticnet" },
    { value: "none", label: "none" },
];

const delayFunction = <F extends (...args: any[]) => void>(
    fn: F,
    ms: number
): ((...args: Parameters<F>) => void) => {
    let timer: NodeJS.Timeout | null = null;

    return function (...args: Parameters<F>) {
        clearTimeout(timer as NodeJS.Timeout);
        timer = setTimeout(() => {
            fn(...(args as Parameters<F>));
        }, ms);
    };
};

const delayNumberInputLog = delayFunction(
    (type: string, context: any) => debouncedAutoSave(type, context),
    1000
);

const LogisticRegressionSettingsComponent = (props: { data: LogisticRegressionSettings }) => {
    const {
        initialSolver,
        initialPenalty,
        initialC,
        initialMaxIter,
        initialL1Ratio,
        updateL1Ratio,
        updateSolver,
        updatePenalty,
        updateC,
        updateMaxIter,
    } = props.data;

    const [solver, setSolver] = useState<string>(initialSolver);
    const [penalty, setPenalty] = useState<string>(initialPenalty);
    const [C, setC] = useState<number>(initialC);
    const [maxIter, setMaxIter] = useState<number>(initialMaxIter);
    const [l1Ratio, setL1Ratio] = useState<number>(initialL1Ratio);

    const handleCChange = (value: number | null) => {
        if (value !== null) {
            setC(value);
            updateC(value);
        }
        delayNumberInputLog("set_regularization_strength", { regularization_strength: value });
    };

    const handleMaxIterChange = (value: number | null) => {
        if (value !== null) {
            setMaxIter(value);
            updateMaxIter(value);
        }
        delayNumberInputLog("set_max_iterations", { max_iterations: value });
    };

    const handleL1RatioChange = (value: number | null) => {
        if (value !== null) {
            setL1Ratio(value);
            updateL1Ratio(value);
        }
        delayNumberInputLog("set_l1_ratio", { l1_ratio: value });
    };

    const handleSolverChange = (value: string) => {
        setSolver(value);
        updateSolver(value);
        debouncedAutoSave("set_solver_type", { solver_type: value });
    };

    const handlePenaltyChange = (value: string) => {
        setPenalty(value);
        updatePenalty(value);
        debouncedAutoSave("set_penalty_type", { penalty_type: value });
    };

    return (
        <div className="logisticRegressionSettingsContainer">
            <Drag.NoDrag>
                <Space size={"middle"} direction="vertical" align="end">
                    <div className="labelContainer">
                        <label>Solver</label>
                        <Select
                            defaultValue={solver}
                            onChange={handleSolverChange}
                            dropdownStyle={{ width: "max-content" }}
                            options={solverOptions.map((option) => ({
                                value: option.value,
                                label: option.label,
                                title: `Select ${option.label}`,
                            }))}
                        />
                    </div>
                    <div className="labelContainer">
                        <label>Penalty</label>
                        <Select
                            defaultValue={penalty}
                            onChange={handlePenaltyChange}
                            dropdownStyle={{ width: "max-content" }}
                            options={penaltyOptions.map((option) => ({
                                value: option.value,
                                label: option.label,
                                title: `Select ${option.label}`,
                            }))}
                        />
                    </div>
                    {penalty === "elasticnet" && (
                        <div className="labelContainer">
                            <label>L1 Ratio</label>
                            <InputNumber
                                name="l1RatioInput"
                                min={0}
                                max={1}
                                step={0.1}
                                value={l1Ratio}
                                onChange={handleL1RatioChange}
                            />
                        </div>
                    )}
                    <div className="labelContainer">
                        <label>Reg Strength (C)</label>
                        <InputNumber
                            name="regStrengthInput"
                            min={0.01}
                            max={1000}
                            step={0.01}
                            defaultValue={C}
                            onChange={handleCChange}
                        />
                    </div>
                    <div className="labelContainer">
                        <label>Max Iterations</label>
                        <InputNumber
                            name="maxIterationsInput"
                            min={1}
                            max={10000}
                            defaultValue={maxIter}
                            onChange={handleMaxIterChange}
                        />
                    </div>
                </Space>
            </Drag.NoDrag>
        </div>
    );
};

export default LogisticRegressionSettingsComponent;
