import { ProblemContextType } from "../../contexts/ProblemContext";
import { ClassicPreset } from "rete";

export class DataframeLoaderControl extends ClassicPreset.Control {
    constructor(
        public loadingStatus: string,
        public messages: string[] = [],
        public context: ProblemContextType
    ) {
        super();
    }

    setLoadingStatus(loadingStatus: string) {
        this.loadingStatus = loadingStatus;
    }
    clearMessages() {
        this.messages = [];
    }
    addMessage(message: string) {
        this.messages.push(message);
    }
}
